import { DEFAULT_LOCALE } from 'data-access/domain/constants'

import { defaultPaths } from './constants'
import { siteUrl } from './siteUrl'
import { BuildUrlOptions, SanityDocument } from './types'

/**
 * Builds a URL for a Sanity document based on its type and locale
 * @param document - The Sanity document
 * @param options - Configuration options including locale and defaultLocale
 * @returns The constructed URL
 */
export const buildUrl = (document: SanityDocument, options: BuildUrlOptions): string => {
  const { locale, paths = defaultPaths } = options
  const isDefaultLocale = locale === DEFAULT_LOCALE
  const localePrefix = isDefaultLocale ? '' : `/${locale}`
  const cleanSiteUrl = siteUrl.replace(/\/+$/, '')
  const baseUrl = `${cleanSiteUrl}${localePrefix}`

  if (!document?._type) {
    return baseUrl
  }

  const slug = document?.slug?.current

  switch (document._type) {
    case 'product':
    case 'collection':
    case 'pageAccount':
    case 'pageArticle':
      return `${baseUrl}/${paths[document._type]}/${slug}`

    case 'homePage':
      return baseUrl

    case 'faqQuestion':
      return `${baseUrl}/${paths.faqQuestion}?faq=${encodeURIComponent(document._id || '')}`

    case 'page':
    case 'pageWishlist':
    case 'pageSearch':
    case 'pageWithRequirements':
      return `${baseUrl}/${slug}`

    default:
      return baseUrl
  }
}
