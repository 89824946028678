import { defineQuery } from 'next-sanity'
import { z } from 'zod'

export const EmissionsSchema = z.object({
  rawMaterial: z.number(),
  manufacturing: z.number(),
  packaging: z.number(),
  distribution: z.number(),
  productUse: z.number(),
  endOfLife: z.number(),
})

export type Emissions = z.infer<typeof EmissionsSchema>

export const EMISSIONS_FRAGMENT = defineQuery(`
  rawMaterial,
  manufacturing,
  packaging,
  distribution,
  productUse,
  endOfLife
`)
