import { z } from 'zod'

type ValidationError = Error & {
  fields?: Array<{
    path: string
    message: string
  }>
}

type ValidateOptions = {
  errorMessage?: string
  enabled?: boolean
}

export const validate = <T extends z.ZodType>(schema: T, rawData: unknown, options?: ValidateOptions): z.infer<T> => {
  // If validation is disabled, return the raw data, this is useful for draftMode
  if (!options?.enabled) {
    return rawData
  }

  const result = schema.safeParse(rawData)

  if (result.success) {
    return result.data
  } else {
    // Create a custom error with the fields attached
    const validationError = new Error(options?.errorMessage || 'Validation failed: ') as ValidationError
    validationError.fields = result?.error.errors.map((err) => ({
      path: err.path.join('.'),
      message: err.message,
    }))

    // Add the original Zod error for reference
    validationError.cause = result.error

    throw validationError
  }
}

export const PromiseAllCatch = async <T extends readonly unknown[]>(fetches: readonly [...{ [K in keyof T]: Promise<T[K]> }], errorPrefix: string) => {
  return Promise.all(
    fetches.map((fetch) =>
      fetch.catch((error) => {
        console.error(errorPrefix, error)
        return null
      }),
    ),
  ) as Promise<{ [K in keyof T]: T[K] | null }>
}
