import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SlugSchema } from '../../types'
import { coalesceFilter, coalesceQuery } from '../../utils'

const LinkTypeSchema = z.enum([
  'homePage',
  'page',
  'product',
  'collection',
  'pageCollection',
  'pageBlogOverview',
  'pageArticle',
  'pageStory',
  'pageWithRequirements',
  'pageAccount',
])

export const LinkSchema = z.object({
  _type: z.literal('link'),
  linkText: z.string().optional(),
  type: z.enum(['external', 'phone', 'email', 'internal', 'scrollToSection']).optional(),
  scrollToSection: z
    .object({
      _id: z.string(),
    })
    .optional(),
  phone: z.string().optional(),
  email: z.string().optional(),
  externalLink: z.string().optional(),
  page: z
    .object({
      slug: SlugSchema.optional(),
      _type: LinkTypeSchema.optional(),
    })
    .optional(),
  newTab: z.boolean().optional(),
})

export type Link = z.infer<typeof LinkSchema>

export const LinkWithShowInLocalesSchema = LinkSchema.extend({
  page: z
    .object({
      slug: SlugSchema.optional(),
      showInLocales: z.boolean().optional(),
      _type: LinkTypeSchema.optional(),
    })
    .optional(),
})

export type LinkWithShowInLocales = z.infer<typeof LinkWithShowInLocalesSchema>

export const LINK_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type,
  type,
  ${coalesceQuery('linkText', locale)},
  defined(newTab) => {newTab},
  defined(page) => {
    page-> {
      _type,
      defined(slug) => {
        'slug': coalesce(${coalesceFilter('slug', locale)}, slug),
      }
    }
  },
  defined(externalLink) => {
    ${coalesceQuery('externalLink', locale)}
  },
  defined(email) => {
    ${coalesceQuery('email', locale)}
  },
  defined(phone) => {
    ${coalesceQuery('phone', locale)}
  },
  defined(scrollToSection) => {
    scrollToSection-> {
      _id
    }
  },
`)

/**
 * Link fragment with nested showInLocales.
 * TODO: This is a temporary fragment for the global navigation A/B/C test. Fix query when test is over.
 */
export const LINK_FRAGMENT_WITH_SHOW_IN_LOCALES = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type,
  type,
  ${coalesceQuery('linkText', locale)},
  defined(newTab) => {newTab},
  defined(page) => {
    page-> {
      _type,
      defined(slug) => {
        'slug': coalesce(${coalesceFilter('slug', locale)}, slug),
      },
      'showInLocales': showInLocales[$locale]
    }
  },
  defined(externalLink) => {
    ${coalesceQuery('externalLink', locale)}
  },
  defined(email) => {
    ${coalesceQuery('email', locale)}
  },
  defined(phone) => {
    ${coalesceQuery('phone', locale)}
  },
  defined(scrollToSection) => {
    scrollToSection-> {
      _id
    }
  },
`)
