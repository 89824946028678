import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'

export const SectionBusinessReviewsSchema = z.object({
  _type: z.literal('sectionBusinessReviews'),
  _id: z.string(),
  title: z.string().optional(),
})

export type SectionBusinessReviewsData = z.infer<typeof SectionBusinessReviewsSchema>

export const SECTION_BUSINESS_REVIEWS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionBusinessReviews' => {
    _type,
    _id,
    defined(title) => {
      ${coalesceQuery('title', locale)}
    },
  }
`)
