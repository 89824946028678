import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SlugSchema } from '../../types'
import { coalesceQuery } from '../../utils'
import { SECTION_TEXT_FRAGMENT, SectionTextSchema } from '../sections/sectionText.fragment'
import { MEDIA_FRAGMENT, MediaSchema } from './media.fragment'

export const ArticleSchema = z.object({
  _type: z.enum(['pagePressRelease', 'pageArticle']),
  _id: z.string(),
  dateCreated: z.string().date(),
  slug: SlugSchema,
  title: z.string(),
  articleThumbnailImage: MediaSchema,
  sections: z.array(SectionTextSchema),
})

export type Article = z.infer<typeof ArticleSchema>

export const RELATED_ARTICLES_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type,
  _id,
  dateCreated,
  ${coalesceQuery('slug', locale)},
  ${coalesceQuery('title', locale)},
  articleThumbnailImage-> {
    ${MEDIA_FRAGMENT()}
  },
  "sections" : *[_type == "sectionText" && _id in ^.sections[]._ref] {
    ${SECTION_TEXT_FRAGMENT(locale)},
  }
`)
