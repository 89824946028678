import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceLocalizedObject } from '../../utils'
import { PRODUCT_CARD_PRODUCT_FRAGMENT, ProductCardProductSchema } from '../components/productCardProduct.fragment'

export const SectionNewArrivalsBestsellersSchema = z.object({
  _id: z.string(),
  _type: z.literal('sectionNewArrivalsBestsellers'),
  position: z.enum(['beforeShopByCollection', 'afterShopByCollection']),
  bestsellers: z.array(ProductCardProductSchema),
  newArrivals: z.array(ProductCardProductSchema),
})

export type SectionNewArrivalsBestsellersData = z.infer<typeof SectionNewArrivalsBestsellersSchema>

export const SECTION_NEW_ARRIVALS_BESTSELLERS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
    _type == 'sectionNewArrivalsBestsellers' => {
      _id,
      _type,
      position,
      ...newArrivalsBestsellersProducts-> {
        ${coalesceLocalizedObject('bestsellers', locale, 'bestsellers', `.value[]-> { ${PRODUCT_CARD_PRODUCT_FRAGMENT(locale)} }`)},
        ${coalesceLocalizedObject('newArrivals', locale, 'newArrivals', `.value[]-> { ${PRODUCT_CARD_PRODUCT_FRAGMENT(locale)} }`)}
      }
    }
`)
