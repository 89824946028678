import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'

export const SectionReturnFormSchema = z.object({
  _id: z.string(),
  _type: z.literal('sectionReturnForm'),
  title: z.string(),
  description: z.optional(RichTextSchema),
  orderNumberLabel: z.string().optional(),
  orderNumberPlaceholder: z.string().optional(),
  orderNumberHint: z.string().optional(),
  postcodeOrEmailLabel: z.string().optional(),
  postcodeOrEmailPlaceholder: z.string().optional(),
  postcodeOrEmailHint: z.string().optional(),
  buttonText: z.string(),
})

export type SectionReturnFormData = z.infer<typeof SectionReturnFormSchema>

export const SECTION_RETURN_FORM_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
_type == 'sectionReturnForm' => {
  _id,
  _type,
  ${coalesceQuery('title', locale)},
  defined(description) => {
    ${RICH_TEXT_FRAGMENT(locale, 'description')},
  },
  defined(orderNumberLabel) => {
    ${coalesceQuery('orderNumberLabel', locale)},
  },
  defined(orderNumberPlaceholder) => {
    ${coalesceQuery('orderNumberPlaceholder', locale)},
  },
  defined(orderNumberHint) => {
    ${coalesceQuery('orderNumberHint', locale)},
  },
  defined(postcodeOrEmailLabel) => {
    ${coalesceQuery('postcodeOrEmailLabel', locale)},
  },
  defined(postcodeOrEmailPlaceholder) => {
    ${coalesceQuery('postcodeOrEmailPlaceholder', locale)},
  },
  defined(postcodeOrEmailHint) => {
    ${coalesceQuery('postcodeOrEmailHint', locale)},
  },
  ${coalesceQuery('buttonText', locale)},
}
`)
