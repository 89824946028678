import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'

export const SectionFilterConfigurationSchema = z.object({
  _type: z.literal('filterConfiguration'),
  _id: z.string(),
  name: z.string(),
  defaultSorting: z.string(),
  enableSorting: z.boolean(),
  sortingOptions: z.array(
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ),
  enableFilters: z.boolean(),
  filterOptions: z.array(
    z.object({
      label: z.string(),
      value: z.string(),
      operator: z.enum(['and', 'or']),
    }),
  ),
  filtersDefaultOpen: z.boolean(),
  pageSize: z.number(),
})

export type SectionFilterConfigurationData = z.infer<typeof SectionFilterConfigurationSchema>

export const SECTION_FILTER_CONFIGURATION_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'filterConfiguration' => {
    _type,
    _id,
    name,
    'defaultSorting': coalesce(defaultSorting, 'relevance'),
    enableSorting,
    enableFilters,
    filtersDefaultOpen,
    "sortingOptions": sortingOptions[] {
        label,
        value
    },
    "filterOptions": filterOptions[]-> {
      ${coalesceQuery('name', locale, 'label')},
      value,
      operator
    },
    "pageSize": coalesce(pageSize, 1000)
  }
`)
