import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { LINK_FRAGMENT, LinkSchema } from '../components/link.fragment'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'

export const SectionRegistrationSchema = z.object({
  _id: z.string(),
  _type: z.literal('sectionRegistration'),
  title: z.string(),
  subtitle: z.string().optional(),
  backgroundImage: MediaSchema,
  firstNameLabel: z.string(),
  firstNameErrorMessage: z.string(),
  lastNameLabel: z.string(),
  lastNameErrorMessage: z.string(),
  emailLabel: z.string(),
  emailErrorMessage: z.string(),
  passwordLabel: z.string(),
  passwordErrorMessage: z.string(),
  confirmPasswordLabel: z.string(),
  passwordMatchErrorMessage: z.string(),
  submitButtonText: z.string(),
  alreadyHaveAccountLink: LinkSchema,
})

export type SectionRegistrationData = z.infer<typeof SectionRegistrationSchema>

export const SECTION_REGISTRATION_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionRegistration' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    defined(subtitle) => {
      ${coalesceQuery('subtitle', locale)}
    },
    backgroundImage -> {
      ${MEDIA_FRAGMENT()}
    },
    ${coalesceQuery('firstNameLabel', locale)},
    ${coalesceQuery('firstNameErrorMessage', locale)},
    ${coalesceQuery('lastNameLabel', locale)},
    ${coalesceQuery('lastNameErrorMessage', locale)},
    ${coalesceQuery('emailLabel', locale)},
    ${coalesceQuery('emailErrorMessage', locale)},
    ${coalesceQuery('passwordLabel', locale)},
    ${coalesceQuery('passwordErrorMessage', locale)},
    ${coalesceQuery('confirmPasswordLabel', locale)},
    ${coalesceQuery('passwordMatchErrorMessage', locale)},
    ${coalesceQuery('submitButtonText', locale)},
    alreadyHaveAccountLink-> {
      ${LINK_FRAGMENT(locale)}
    }
  }
`)
