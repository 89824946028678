import { SupportedLocale } from '../domain/constants'
import { algoliaClient, buildObjectID, getAlgoliaIndexName } from './utils'

const searchClient = algoliaClient()

interface AlgoliaOperationParams {
  locale?: SupportedLocale
  indexName?: string
  objects: any[]
  createIfNotExists?: boolean
  requestId?: string
}

interface AlgoliaDeleteParams {
  locale: SupportedLocale
  objectIDs: string[]
  requestId?: string
}

interface AlgoliaProductDeleteParams {
  slug: string
  locale: SupportedLocale
  variants?: string[]
  requestId?: string
}

/**
 * Replaces all objects in an Algolia index.
 *
 * @see https://www.algolia.com/doc/libraries/javascript/v5/helpers/#replace-all-records
 */
export const replaceAllAlgoliaObjects = async ({ indexName, objects, requestId }: Omit<AlgoliaOperationParams, 'locale' | 'createIfNotExists'> & { indexName: string }) => {
  console.info('[Algolia] Starting full index replacement', {
    requestId,
    indexName,
    objectCount: objects.length,
  })

  try {
    const result = await searchClient.replaceAllObjects({ indexName, objects: [...objects] })
    console.info('[Algolia] Index replacement completed', {
      requestId,
      indexName,
      objectCount: objects.length,
    })
    return result
  } catch (error) {
    console.error('[Algolia] Failed to replace index objects', {
      requestId,
      error,
      indexName,
      objectCount: objects.length,
    })
    throw error
  }
}

/**
 * Upsert records in an Algolia index.
 *
 * @see https://www.algolia.com/doc/libraries/javascript/v5/helpers/#update-attributes-of-records
 */
export const createOrUpdateAlgoliaObjects = async ({
  locale,
  objects,
  createIfNotExists = true,
  requestId,
}: Omit<AlgoliaOperationParams, 'indexName'> & { locale: SupportedLocale }) => {
  const indexName = getAlgoliaIndexName(locale)
  console.info('[Algolia] Starting upsert operation', {
    requestId,
    indexName,
    objectCount: objects.length,
    createIfNotExists,
  })

  try {
    const batches = await searchClient.partialUpdateObjects({
      indexName,
      objects: [...objects],
      createIfNotExists,
    })

    console.debug('[Algolia] Created batch operations', {
      requestId,
      indexName,
      batchCount: batches.length,
      taskIds: batches.map((b) => b.taskID),
    })

    const results = await Promise.allSettled(batches.map((batch) => searchClient.waitForTask({ indexName, taskID: batch.taskID })))

    const failed = results.filter((r) => r.status === 'rejected')
    if (failed.length > 0) {
      console.warn('[Algolia] Some batch operations failed', {
        requestId,
        indexName,
        failedCount: failed.length,
        totalCount: results.length,
        errors: failed.map((f) => (f.status === 'rejected' ? f.reason : null)),
      })
    } else {
      console.info('[Algolia] All batch operations completed', {
        requestId,
        indexName,
        objectCount: objects.length,
      })
    }

    return results
  } catch (error) {
    console.error('[Algolia] Failed to update objects', {
      requestId,
      error,
      indexName,
      objectCount: objects.length,
    })
    throw error
  }
}

/**
 * Removes objects from an Algolia index.
 *
 * @see https://www.algolia.com/doc/libraries/javascript/v5/helpers/#delete-records
 */
export const deleteAlgoliaObjects = async ({ locale, objectIDs, requestId }: AlgoliaDeleteParams) => {
  const indexName = getAlgoliaIndexName(locale)

  console.info('[Algolia] Starting delete operation', {
    requestId,
    indexName,
    objectCount: objectIDs.length,
  })

  try {
    const batches = await searchClient.deleteObjects({ indexName, objectIDs })
    console.debug('[Algolia] Created delete batches', {
      requestId,
      indexName,
      batchCount: batches.length,
      taskIds: batches.map((b) => b.taskID),
    })

    const results = await Promise.allSettled(batches.map((batch) => searchClient.waitForTask({ indexName, taskID: batch.taskID })))

    const failed = results.filter((r) => r.status === 'rejected')
    if (failed.length > 0) {
      console.warn('[Algolia] Delete operations failed', {
        requestId,
        indexName,
        failedCount: failed.length,
        totalCount: results.length,
        errors: failed.map((f) => (f.status === 'rejected' ? f.reason : null)),
      })
    } else {
      console.info('[Algolia] Delete operations completed', {
        requestId,
        indexName,
        objectCount: objectIDs.length,
      })
    }

    return results
  } catch (error) {
    console.error('[Algolia] Failed to delete objects', {
      requestId,
      error,
      indexName,
      objectCount: objectIDs.length,
    })
    throw error
  }
}

/**
 * Removes objects from an Algolia index by product variant IDs.
 * @param slug - The slug of the product
 * @param locale - The locale of the product
 * @param variants - The object IDs of the product variants
 */
export const deleteAlgoliaProducts = async ({ slug, locale, variants = [], requestId }: AlgoliaProductDeleteParams) => {
  console.info('[Algolia] Starting product deletion', {
    requestId,
    slug,
    locale,
    variantCount: variants?.length,
  })

  try {
    if (!variants || variants?.length === 0) {
      const objectId = buildObjectID('product', slug)
      console.debug('[Algolia] Removing base product', {
        requestId,
        objectId,
        locale,
      })
      return deleteAlgoliaObjects({ locale, objectIDs: [objectId], requestId })
    }

    console.debug('[Algolia] Removing product variants', {
      requestId,
      slug,
      locale,
      variants,
    })
    return deleteAlgoliaObjects({ locale, objectIDs: variants, requestId })
  } catch (error) {
    console.error('[Algolia] Failed to delete products', {
      requestId,
      error,
      slug,
      locale,
      variants,
    })
    throw error
  }
}
