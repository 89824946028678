import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SectionType } from '../common/sectionTypes.fragment'
import { Section, SECTION_LIST, SectionSchema } from '../pages/sections.fragment'

export const contextualizedValueSchema = z.object({
  name: z.enum(['base', 'md', 'lg']),
  value: z.number(),
})

export type contextualizedValue = z.infer<typeof contextualizedValueSchema>

export const sectionLayoutBaseSchema = z.object({
  _id: z.string(),
  _type: z.literal('sectionLayout'),
  columns: z.array(contextualizedValueSchema),
  containerized: z.enum(['medium', 'small']).optional(),
  padding: z.boolean().optional(),
  backgroundColor: z.string().optional(),
})

export type SectionLayoutData = z.infer<typeof sectionLayoutBaseSchema> & {
  sections?: ({
    colSpan: contextualizedValue[]
    rowSpan: contextualizedValue[]
    orderOverride?: contextualizedValue[]
  } & Section)[]
}

const sectionWrapperSchema = z.object({
  colSpan: z.array(contextualizedValueSchema),
  rowSpan: z.array(contextualizedValueSchema),
  orderOverride: z.array(contextualizedValueSchema).optional(),
})

export const SectionLayoutSchema: z.ZodType<SectionLayoutData> = sectionLayoutBaseSchema.extend({
  sections: z.array(sectionWrapperSchema.and(z.lazy(() => SectionSchema))).optional(),
})

export const SECTION_LAYOUT_FRAGMENT = (locale = DEFAULT_LOCALE, types: SectionType[]) => {
  const sections = types.map((type) => type?.sections).filter(Boolean)

  if (sections.length === 0) {
    return ''
  }

  return defineQuery(`
    _type == 'sectionLayout' => {
      _id,
      _type,
      "columns": array::compact(columns[defined(value)]{
        name,
        value,
      }),
      sections[] {
        ...section-> {
          ${sections.map((section): string => `${SECTION_LIST(locale, section as SectionType[])}`)}
        },
        "colSpan": array::compact(colSpan[defined(value)]{
          name,
          value,
        }),
        "rowSpan": array::compact(rowSpan[defined(value)]{
          name,
          value,
        }),
        "orderOverride": array::compact(orderOverride[defined(value)]{
          name,
          value,
        }),
      },
      defined(containerized) => {
        containerized,
      },
      defined(padding) => {
        padding,
      },
      defined(backgroundColor) => {
        'backgroundColor': backgroundColor.value
      },
    }
  `)
}
