import { QueryClient } from '@tanstack/react-query'

import { RequestError } from '../error/Request.error'

/**
 * @tanstack/react-query client with default settings
 * @see: https://tanstack.com/query/latest/docs/framework/react/overview
 */
export const queryClient = new QueryClient()

/**
 * SWR fetcher.
 *
 * @see https://swr.vercel.app/docs/getting-started
 */
export default async function fetcher<JSON = any>(url: string) {
  return get<JSON>(url)
}

/**
 * JSON request.
 */
export async function request<JSON = any>(request: Request, parseResponse = true) {
  const response = await fetch(request)

  if (response.ok && response.status !== 204 && request.method !== 'HEAD') {
    return parseResponse ? await response.json() : response
  }

  // Fetch does not throw error on all response statuses by default, hence we do it manually
  if (!response.ok) {
    const { error } = await response.json()
    throw new RequestError(error || response.statusText, response.status, response)
  }

  return undefined
}

/**
 * JSON request headers.
 */
export const jsonHeaders = (headers?: Record<string, string>): Record<string, string> => {
  return {
    ...headers,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
}

/**
 * GET request.
 */
export async function get<T>(url: Readonly<string>, headers: { [key: string]: string } | false = {}, signal?: AbortSignal | null): Promise<T | undefined> {
  return request<T>(new Request(url, { method: 'GET', headers: headers !== false ? jsonHeaders(headers) : undefined, signal }))
}

/**
 * POST request.
 */
export async function post<T>(
  url: Readonly<string>,
  body: { [key: string]: any } = {},
  headers: { [key: string]: string } | false = {},
  signal?: AbortSignal | null,
  parseResponse = true,
): Promise<T | undefined> {
  return request<T>(new Request(url, { method: 'POST', body: JSON.stringify(body), headers: headers !== false ? jsonHeaders(headers) : undefined, signal }), parseResponse)
}

/**
 * PUT request.
 */
export async function put<T>(
  url: Readonly<string>,
  body: { [key: string]: any } = {},
  headers: { [key: string]: string } | false = {},
  signal?: AbortSignal | null,
): Promise<T | undefined> {
  return request<T>(new Request(url, { method: 'PUT', body: JSON.stringify(body), headers: headers !== false ? jsonHeaders(headers) : undefined, signal }))
}

/**
 * PATCH request.
 */
export async function patch<T>(
  url: Readonly<string>,
  body: { [key: string]: any } = {},
  headers: { [key: string]: string } | false = {},
  signal?: AbortSignal | null,
): Promise<T | undefined> {
  return request<T>(new Request(url, { method: 'PATCH', body: JSON.stringify(body), headers: headers !== false ? jsonHeaders(headers) : undefined, signal }))
}

/**
 * DELETE request.
 */
export async function del(url: Readonly<string>, headers: { [key: string]: string } | false = {}, signal?: AbortSignal | null): Promise<void> {
  await request(new Request(url, { method: 'DELETE', headers: headers !== false ? jsonHeaders(headers) : undefined, signal }))
}

/**
 * HEAD request.
 */
export async function head(url: Readonly<string>, headers: { [key: string]: string } | false = {}, signal?: AbortSignal | null): Promise<void> {
  await request(new Request(url, { method: 'HEAD', headers: headers !== false ? jsonHeaders(headers) : undefined, signal }))
}
