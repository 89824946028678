import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SectionType } from '../common/sectionTypes.fragment'
import { Section, SECTION_LIST, SectionSchema } from '../pages/sections.fragment'

const SectionHalfAndHalfBaseSchema = z.object({
  _type: z.literal('sectionHalfAndHalf'),
  _id: z.string(),
  distribution: z.string(),
})

export type SectionHalfAndHalfData = z.infer<typeof SectionHalfAndHalfBaseSchema> & {
  leftSections: Section[]
  rightSections: Section[]
}

export const SectionHalfAndHalfSchema: z.ZodType<SectionHalfAndHalfData> = SectionHalfAndHalfBaseSchema.extend({
  leftSections: z.lazy(() => SectionSchema.array()),
  rightSections: z.lazy(() => SectionSchema.array()),
})

export const SECTION_HALF_AND_HALF_FRAGMENT = (locale = DEFAULT_LOCALE, types: SectionType[]) => {
  const leftSectionsTypes = types.map((type) => type?.leftSections).filter(Boolean) || []
  const rightSectionsTypes = types.map((type) => type?.rightSections).filter(Boolean) || []

  if (leftSectionsTypes.length === 0 && rightSectionsTypes.length === 0) {
    return ''
  }

  return defineQuery(`
  _type == 'sectionHalfAndHalf' => {
  ...,
    _type,
    _id,
    ${
      leftSectionsTypes.length === 0
        ? ''
        : `leftSections[]-> {
      ${leftSectionsTypes.map((section): string => `${SECTION_LIST(locale, section as SectionType[])},`)}
    },
    `
    }
    ${
      rightSectionsTypes.length === 0
        ? ''
        : `rightSections[]-> {
      ${rightSectionsTypes.map((section): string => `${SECTION_LIST(locale, section as SectionType[])},`)}
    },
    `
    }
  }
`)
}
