import { defineQuery } from 'next-sanity'
import { z } from 'zod'

export const SectionOrdersOverviewSchema = z.object({
  _type: z.literal('sectionOrdersOverview'),
  _id: z.string(),
})

export type SectionOrdersOverviewData = z.infer<typeof SectionOrdersOverviewSchema>

export const SECTION_ORDERS_OVERVIEW_FRAGMENT = () =>
  defineQuery(`
  _type == 'sectionOrdersOverview' => {
    _type,
    _id,
  }
`)
