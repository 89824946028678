import { Slug } from 'data-access/sanity/types'

import { LinkType } from './types'

/**
 * Convert a slug to a link
 * @param slug - The slug to convert
 * @param pageType - The type of page
 * @param newTab - Whether to open the link in a new tab
 * @returns The link
 */
export const convertSlug = (slug?: Slug, pageType?: LinkType, newTab = false) => {
  return {
    page: {
      _type: pageType,
      slug,
    },
    newTab,
  }
}
