import { defineQuery } from 'next-sanity'

import { DEFAULT_LOCALE } from '../../../../domain/constants'
import { coalesceQuery } from '../../../utils'

// Global collections; should also be in Sanity schema
// @see apps/studio/schemas/dictionary/global-collections.js
export const globalCollectionsText: string[] = [
  'filtersTitle',
  'applyButtonText',
  'noProductsFound',
  'useFewerFiltersOr',
  'clearAll',
  'trousers',
  'shorts',
  'tops',
  'shoes',
  'other',
  'filterAndSort',
  'applyFilters',
  'clearFilters',
  'sortBy',
  'relevance',
  'bestsellers',
  'newest',
  'price_asc',
  'price_desc',
  'searchItem',
  'searchItems',
  'showLess',
  'showMore',
  'aw',
  'ss',
  'as',
]

export const GLOBAL_COLLECTIONS_FRAGMENT = (locale = DEFAULT_LOCALE) => defineQuery(globalCollectionsText.map((term) => `${coalesceQuery(term, locale)}`).join(','))
