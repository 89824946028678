import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'

const BulletPointSchema = z.object({
  icon: z.string().optional(),
  title: z.string().optional(),
  text: RichTextSchema.optional(),
})

export const SectionBulletPointsSchema = z.object({
  _type: z.literal('sectionBulletPoints'),
  _id: z.string(),
  title: z.string().optional(),
  bulletPoints: z.array(BulletPointSchema).optional(),
})

export type SectionBulletPointsData = z.infer<typeof SectionBulletPointsSchema>

export const SECTION_BULLET_POINTS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionBulletPoints' => {
    _type,
    _id,
    defined(title) => {
      ${coalesceQuery('title', locale)}
    },
    defined(bulletPoints) => {
      bulletPoints[] {
        defined(icon) => { icon },
        defined(title) => {
          ${coalesceQuery('title', locale)}
        },
        defined(text) => {
          ${RICH_TEXT_FRAGMENT(locale, 'text')}
        },
      }
    }
  }
`)
