import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { LINK_FRAGMENT, LinkSchema } from '../components/link.fragment'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'

export const SectionInvalidTokenSchema = z.object({
  _id: z.string(),
  _type: z.literal('sectionInvalidToken'),
  title: z.string(),
  subtitle: z.string().optional(),
  backgroundImage: MediaSchema,
  backLink: LinkSchema,
})

export type SectionInvalidTokenData = z.infer<typeof SectionInvalidTokenSchema>

export const SECTION_INVALID_TOKEN_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionInvalidToken' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    defined(subtitle) => {
      ${coalesceQuery('subtitle', locale)}
    },
    backgroundImage-> {
      ${MEDIA_FRAGMENT()}
    },
    backLink-> {
      ${LINK_FRAGMENT(locale)}
    }
  }
`)
