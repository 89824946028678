import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { LINK_FRAGMENT, LinkSchema } from '../components/link.fragment'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'

export const SectionLoginSchema = z.object({
  _type: z.literal('sectionLogin'),
  _id: z.string(),
  title: z.string(),
  subtitle: z.string().optional(),
  backgroundImage: MediaSchema,
  emailLabel: z.string(),
  emailErrorMessage: z.string(),
  passwordLabel: z.string(),
  passwordErrorMessage: z.string(),
  forgottenPasswordLink: LinkSchema,
  loginErrorMessage: z.string(),
  submitButtonText: z.string(),
  createAccountTitle: z.string().optional(),
  createAccountSubtitle: z.string().optional(),
  createAccountLink: LinkSchema.optional(),
})

export type SectionLoginData = z.infer<typeof SectionLoginSchema>

export const SECTION_LOGIN_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionLogin' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    defined(subtitle) => {
      ${coalesceQuery('subtitle', locale)}
    },
    backgroundImage-> {
      ${MEDIA_FRAGMENT()}
    },
    ${coalesceQuery('emailLabel', locale)},
    ${coalesceQuery('emailErrorMessage', locale)},
    ${coalesceQuery('passwordLabel', locale)},
    ${coalesceQuery('passwordErrorMessage', locale)},
    forgottenPasswordLink-> {
      ${LINK_FRAGMENT(locale)}
    },
    ${coalesceQuery('loginErrorMessage', locale)},
    ${coalesceQuery('submitButtonText', locale)},
    defined(createAccountTitle) => {
      ${coalesceQuery('createAccountTitle', locale)}
    },
    defined(createAccountSubtitle) => {
      ${coalesceQuery('createAccountSubtitle', locale)}
    },
    defined(createAccountLink) => {
      createAccountLink-> {
        ${LINK_FRAGMENT(locale)}
      }
    },
  }
`)
