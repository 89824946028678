import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SlugSchema } from '../../types'
import { coalesceQuery } from '../../utils'
import { LINK_FRAGMENT, LinkSchema } from './link.fragment'

const CollectionSchema = z.object({
  _type: z.literal('collection'),
  _id: z.string(),
  slug: SlugSchema,
  new: z.boolean().optional(),
  collectionTitle: z.string(),
})

export const FooterMenuItemSchema = z.object({
  _type: z.string(),
  title: z.string(),
  titleLink: LinkSchema.optional(),
  footerMenuItems: z.array(z.union([LinkSchema, CollectionSchema])).optional(),
  showInLocales: z.boolean(),
})

export type FooterMenuItem = z.infer<typeof FooterMenuItemSchema>

export const FOOTER_MENU_ITEM_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type,
  ${coalesceQuery('title', locale)},
  defined(titleLink) => {
    titleLink-> {
      ${LINK_FRAGMENT(locale)}
    },
  },
  footerMenuItems [@->.showInLocales[$locale] != false]-> {
    _id,
    ${LINK_FRAGMENT(locale)}
    defined(slug) => {
      ${coalesceQuery('slug', locale)},
    },
    defined(new) => {
      new,
    },
    ${coalesceQuery('title', locale, 'collectionTitle')},
  },
  "showInLocales": showInLocales[$locale],
`)
