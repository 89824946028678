import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceLocalizedValue } from '../../utils'
import { Link, LINK_FRAGMENT } from '../components/link.fragment'

export const ACCOUNT_KEYS = [
  'login',
  'register',
  'signIn',
  'signUp',
  'reset',
  'resetPassword',
  'newCustomer',
  'returningCustomer',
  'forgotYourPassword',
  'forgotPassword',
  'yourEmail',
  'password',
  'confirmPassword',
  'activateAccount',
  'submit',
  'saveInfo',
  'changeEmailMessage',
  'currentPassword',
  'newPassword',
  'changePassword',
  'userInfo',
  'personalInfo',
  'orders',
  'streetName',
  'postalCode',
  'city',
  'country',
  'address',
  'saveAddress',
  'checkEmail',
  'passwordsMatchError',
  'passwordChangeSuccess',
  'currentPasswordFailMessage',
  'loginFailMessage',
  'userInfoChangeSuccessMesssage',
  'shippingAddress',
  'billingAddress',
  'orderTotal',
  'tax',
  'shipping',
  'subtotal',
  'delivered',
  'shipped',
  'processing',
  'orderPlaced',
  'trackShipment',
  'order',
  'date',
  'requestReturn',
  'logout',
  'myAccount',
  'forgotPasswordErrorMessage',
  'noOrdersYet',
  'productNoLongerAvailable',
  'invoice',
] as const

export type AccountData = {
  [key in (typeof ACCOUNT_KEYS)[number]]: string
} & {
  shopNowLink: Link
}

export const ACCOUNT_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  `
  ${ACCOUNT_KEYS.map((term) => `${coalesceLocalizedValue(term, locale)}`).join(',')},
  shopNowLink-> {
    ${LINK_FRAGMENT(locale)}
  }
`
