type ErrorWithMessage = {
  message: string
}

/**
 * Check if the error is an instance of ErrorWithMessage
 *
 * @param error - The error to check
 * @returns True if the error is an instance of ErrorWithMessage, false otherwise
 */
const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
  return typeof error === 'object' && error !== null && 'message' in error && typeof (error as Record<string, unknown>).message === 'string'
}

/**
 * Convert the error to an instance of ErrorWithMessage
 *
 * @param maybeError - The error to convert
 * @returns An instance of ErrorWithMessage
 */
const toErrorWithMessage = (maybeError: unknown): ErrorWithMessage => {
  if (isErrorWithMessage(maybeError)) return maybeError

  try {
    return new Error(JSON.stringify(maybeError))
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError))
  }
}

/**
 * Get the message from an error
 *
 * @param error - The error to get the message from
 * @returns The message from the error
 */
export const getErrorMessage = (error: unknown) => {
  return toErrorWithMessage(error).message
}
