import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { LINK_FRAGMENT, LinkSchema } from '../components/link.fragment'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'

export const SectionSupportInfoSchema = z.object({
  _type: z.literal('sectionSupportInfo'),
  _id: z.string(),
  backButton: LinkSchema.optional(),
  icon: z.string().optional(),
  title: z.string().optional(),
  description: RichTextSchema.optional(),
})

export type SectionSupportInfoData = z.infer<typeof SectionSupportInfoSchema>

export const SECTION_SUPPORT_INFO_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionSupportInfo' => {
    _type,
    _id,
    defined(backButton) => {
      backButton-> {
        ${LINK_FRAGMENT(locale)}
      },
    },
    defined(icon) => {
      icon
    },
    defined(title) => {
    ${coalesceQuery('title', locale)}
    },
    defined(description) => {
    ${RICH_TEXT_FRAGMENT(locale, 'description')}
    },
  }
`)
