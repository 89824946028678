import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { COOKIE_TYPE_FRAGMENT, CookieTypeSchema } from '../components/cookieType.fragment'

export const SectionCookieSettingsSchema = z.object({
  _type: z.literal('sectionCookieSettings'),
  _id: z.string(),
  title: z.string(),
  customiseLabel: z.string(),
  viewCookieListLabel: z.string(),
  cookieListTitle: z.string(),
  labelTableHeaderName: z.string(),
  labelTableHeaderProvider: z.string(),
  labelTableHeaderCookies: z.string(),
  cookieTypes: z.array(CookieTypeSchema),
})

export type SectionCookieSettingsData = z.infer<typeof SectionCookieSettingsSchema>

export const SECTION_COOKIE_SETTINGS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionCookieSettings' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    ${coalesceQuery('customiseLabel', locale)},
    ${coalesceQuery('viewCookieListLabel', locale)},
    ${coalesceQuery('cookieListTitle', locale)},
    ${coalesceQuery('labelTableHeaderName', locale)},
    ${coalesceQuery('labelTableHeaderProvider', locale)},
    ${coalesceQuery('labelTableHeaderCookies', locale)},
    cookieTypes[]-> {
      ${COOKIE_TYPE_FRAGMENT(locale)}
    },
  }
`)
