import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SlugSchema } from '../../types'
import { coalesceQuery } from '../../utils'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'
import { ArticleSchema, RELATED_ARTICLES_FRAGMENT } from '../components/relatedArticles.fragments'
import { SECTION_MEDIA_FRAGMENT, SectionMediaSchema } from '../sections/sectionMedia.fragment'
import { SECTION_PADDING_FRAGMENT, SectionPaddingSchema } from '../sections/sectionPadding.fragment'
import { SECTION_TEXT_FRAGMENT, SectionTextSchema } from '../sections/sectionText.fragment'

export const PageArticleSchema = z.object({
  _type: z.string(),
  _id: z.string(),
  slug: SlugSchema,
  dateCreated: z.date(),
  articleMainImage: MediaSchema.optional(),
  sections: z.array(z.union([SectionTextSchema, SectionMediaSchema, SectionPaddingSchema])),
  relatedArticles: z.array(ArticleSchema).optional(),
})

export type PageArticle = z.infer<typeof PageArticleSchema>

export const PAGE_ARTICLE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type,
  _id,
  ${coalesceQuery('slug', locale)},
  dateCreated,
  ${coalesceQuery('title', locale)},
  articleMainImage-> {
    ${MEDIA_FRAGMENT()}
  },
  sections[]-> {
    ${SECTION_TEXT_FRAGMENT(locale)},
    ${SECTION_MEDIA_FRAGMENT()},
    ${SECTION_PADDING_FRAGMENT()}
  },
  "relatedArticles": *[ _type == ^._type && _id != ^._id && showInLocales[$locale] != false && (*[_type == 'sectionArticleOverview' && ^._id in articles[]._ref && (*[_type == 'pageWithRequirements' && ^._id in sections[]._ref][0] != null && showInLocales[$locale] != false)][0] != null)] | order(dateTime(dateCreated) desc)[0...3] {
    ${RELATED_ARTICLES_FRAGMENT(locale)}
  },
`)
