// The allowlist of possible filter group values which are supported in Algolia
// This shared list is currently used in Sanity Studio and to construct URLs on PCPs
export const supportedFilterGroups = [
  { title: 'filterCategory', value: 'filterCategory' },
  { title: 'style', value: 'style' },
  { title: 'sizes', value: 'sizes' },
  { title: 'waistSizes', value: 'waistSizes' },
  { title: 'lengthSizes', value: 'lengthSizes' },
  { title: 'colors', value: 'colors' },
  { title: 'price', value: 'price' },
  { title: 'materials', value: 'materials' },
  { title: 'season', value: 'season' },
  { title: 'sleeveLength', value: 'sleeveLength' },
]
