import { IndexUiState, StateMapping, UiState } from 'instantsearch.js'
import singletonRouter from 'next/router'
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs'
import aa, { InsightsClient } from 'search-insights'

import { supportedFilterGroups } from 'data-access/algolia/constants'
import { INDEX_SUFFIXES } from 'data-access/algolia/utils'

// Please note this is the Insights browser client, not Algolia's API client;
// and most of the client is initialized automatically by the InstantSearch component.
// @see https://www.algolia.com/doc/api-client/methods/insights/
export const algoliaInsightsClient: InsightsClient = aa

/**
 * Custom router configuration for Algolia InstantSearch with Next.js.
 * This function creates a router that syncs the search state with the URL.
 */
export function customRouter(serverUrl: string) {
  return createInstantSearchRouterNext({
    singletonRouter,
    serverUrl,
    routerOptions: {
      cleanUrlOnDispose: false,
    },
  })
}

/**
 * Custom state mapping for Algolia InstantSearch.
 * This function maps the search UI state to the URL and vice versa, allowing for clean and readable URLs.
 * It handles Algolia-specific refinements, range filters, and preserves non-Algolia parameters.
 */
export function customStateMapping(url: string, indexName: string, defaultSorting?: string): StateMapping {
  // Create a Set of supported filter keys for efficient lookup
  const supportedKeys = new Set(supportedFilterGroups.map((group) => group.value))

  const createServerUrl = (_url: string) => {
    if (!/^https?:\/\//i.test(_url)) {
      _url = 'http://' + _url
    }

    return new URL(_url)
  }

  const serverUrl = createServerUrl(url)

  const getBaseIndexName = (fullIndexName: string): string => {
    const suffix = INDEX_SUFFIXES.find((s) => fullIndexName.endsWith(s))
    if (suffix) {
      return fullIndexName.slice(0, -suffix.length - 1) // -1 for the underscore
    }
    return fullIndexName
  }

  const getValidSortBy = (sortBy: string | undefined) => {
    if (!sortBy) {
      return undefined
    }

    const suffix = INDEX_SUFFIXES.find((s) => sortBy.endsWith(s))
    return suffix || undefined
  }

  const baseIndexName = getBaseIndexName(indexName)

  return {
    /**
     * Converts the UI state to URL parameters
     */
    stateToRoute: (uiState: UiState): Record<string, any> => {
      const indexUiState: IndexUiState = uiState[indexName] || {}
      const { sortBy, refinementList, range, configure, ...restUiState } = indexUiState

      // Convert refinement list to URL-friendly format
      const refinements = refinementList
        ? Object.keys(refinementList).reduce(
            (acc, key) => {
              const values = refinementList[key]
              if (values) {
                acc[key] = values.join(',')
              }
              return acc
            },
            {} as Record<string, string>,
          )
        : {}

      // Convert range filters to URL-friendly format
      const ranges = range
        ? Object.keys(range).reduce(
            (acc, key) => {
              const value = range[key]
              if (value) {
                acc[`range_${key}`] = value
              }
              return acc
            },
            {} as Record<string, string>,
          )
        : {}

      // Preserve non-Algolia parameters from the UI state
      const nonAlgoliaParams = Object.keys(restUiState).reduce(
        (acc, key) => {
          if (!supportedKeys.has(key)) {
            acc[key] = restUiState[key as keyof typeof restUiState]
          }
          return acc
        },
        {} as Record<string, any>,
      )

      // Include existing URL parameters that are not related to Algolia
      const currentParams = new URLSearchParams(serverUrl.search)
      const existingParams: Record<string, string> = {}
      currentParams.forEach((value, key) => {
        if (!supportedKeys.has(key) && key !== 'sortBy') {
          existingParams[key] = value
        }
      })

      const validSortBy = getValidSortBy(sortBy) || (defaultSorting !== 'relevance' ? getValidSortBy(defaultSorting) : undefined)

      // Combine all parameters into a single object
      return {
        ...(validSortBy && { sortBy: validSortBy }),
        ...refinements,
        ...ranges,
        ...nonAlgoliaParams,
        ...existingParams,
      }
    },
    /**
     * Converts URL parameters back to the UI state
     */
    routeToState: (routeState: Record<string, any>): UiState => {
      const { sortBy, ...refinementsAndRanges } = routeState
      const refinements: Record<string, string> = {}
      const range: Record<string, string> = {}
      const nonAlgoliaParams: Record<string, any> = {}

      // Categorize parameters into refinements, ranges, and non-Algolia params
      Object.keys(refinementsAndRanges).forEach((key) => {
        if (key.startsWith('range_')) {
          range[key.replace('range_', '')] = refinementsAndRanges[key]
        } else if (supportedKeys.has(key)) {
          refinements[key] = refinementsAndRanges[key]
        } else {
          nonAlgoliaParams[key] = refinementsAndRanges[key]
        }
      })

      // Convert refinements back to the format expected by InstantSearch
      const refinementList = Object.keys(refinements).reduce(
        (acc, key) => {
          const values = refinements[key]
          if (values) {
            acc[key] = values.split(',')
          }
          return acc
        },
        {} as Record<string, string[]>,
      )

      const validSortBy = getValidSortBy(sortBy) || (defaultSorting !== 'relevance' ? getValidSortBy(defaultSorting) : undefined)

      return {
        [indexName]: {
          ...(validSortBy && { sortBy: `${baseIndexName}_${validSortBy}` }),
          refinementList,
          range,
          ...nonAlgoliaParams, // Ensure non-Algolia parameters are preserved in the state
        },
      }
    },
  }
}
