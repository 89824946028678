import { defineQuery } from 'next-sanity'
import z from 'zod'

import { SupportedLocale } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { COOKIE_TYPE_FRAGMENT, CookieTypeSchema } from '../components/cookieType.fragment'
import { LINK_FRAGMENT, LinkSchema } from '../components/link.fragment'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'

export const CookieCustomiserSchema = z.object({
  customiserTitle: z.string(),
  customiseCopy: RichTextSchema.optional(),
  cookieTypes: z.array(CookieTypeSchema).optional(),
  closeCustomiserLabel: z.string().optional(),
})

export const CookieBannerSchema = CookieCustomiserSchema.extend({
  cookieBannerTitle: z.string(),
  cookieBannerDescription: RichTextSchema,
  cookieBannerButtonLabelAcceptAll: z.string(),
  cookieBannerButtonLabelCustomise: z.string(),
  cookieBannerCustomisePage: LinkSchema,
})

export type CookieCustomiser = z.infer<typeof CookieCustomiserSchema>
export type CookieBanner = z.infer<typeof CookieBannerSchema>

export const COOKIE_BANNER_FRAGMENT = (locale: SupportedLocale = 'en') =>
  defineQuery(`
    ${coalesceQuery('cookieBannerTitle', locale)},
    ${RICH_TEXT_FRAGMENT(locale, 'cookieBannerDescription')},
    ${coalesceQuery('cookieBannerButtonLabelCustomise', locale)},
    ${coalesceQuery('cookieBannerButtonLabelAcceptAll', locale)},
    cookieBannerCustomisePage-> {
      ${LINK_FRAGMENT(locale)}
    },
    ${coalesceQuery('customiserTitle', locale)},
    defined(cookieTypes) => {
      cookieTypes[]-> {
        ${COOKIE_TYPE_FRAGMENT(locale)}
      },
    },
    defined(customiseCopy) => {
      ${RICH_TEXT_FRAGMENT(locale, 'customiseCopy')}
    },
    defined(closeCustomiserLabel) => {
      ${coalesceQuery('closeCustomiserLabel', locale)}
    },
  `)
