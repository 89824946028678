import { defineQuery } from 'next-sanity'

export type ActiveCollectionSeason = 'ss' | 'aw'

export const GET_SANITY_ACTIVE_COLLECTION_SEASON_FRAGMENT = () =>
  defineQuery(`
    *[_type == 'seasonSettings'][0] {
      activeCollectionSeason
    }.activeCollectionSeason
`)
