/**
 * Get the base URL for the current environment
 * @returns The base URL
 */
export const getBaseUrl = (): string => {
  // Fill in the base URL from the environment variables
  // If the NEXT_PUBLIC_SITE_URL (Store-app) is set, use it, otherwise use the NEXT_PUBLIC_STORE_SITE_URL (Data-app)
  let url = `${process?.env?.NEXT_PUBLIC_SITE_URL || process?.env?.NEXT_PUBLIC_STORE_SITE_URL}`

  if (typeof window !== 'undefined') {
    url = window.location.origin
  } else if (process.env?.NEXT_PUBLIC_VERCEL_ENV === 'preview') {
    const vercelUrl = process?.env?.NEXT_PUBLIC_VERCEL_URL
    url = vercelUrl ? `https://${vercelUrl}` : 'http://localhost:3000'
  } else if (process?.env?.NODE_ENV === 'development') {
    url = 'http://localhost:3000'
  }

  return url.replace(/\/+$/, '')
}
