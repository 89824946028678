import { DefaultSeoProps, NextSeoProps } from 'next-seo/lib/types'

import { DEFAULT_LOCALE, hreflangMapping, locales, localesWithFallbackForCoalesceQuery, SupportedLocale } from 'data-access/domain/constants'
import { sanityCdnClient } from 'data-access/sanity/clients/sanityClient'
import { Image } from 'data-access/sanity/fragments/common/getImage.fragment'
import { Slug } from 'data-access/sanity/types'
import { getBaseUrl } from 'utilities/string/url'
import { buildUrl } from 'utilities/string/url/buildUrl'
import { SanityDocument } from 'utilities/string/url/types'

import { Seo } from 'src/domain/genericPageProps.domain'
import { imageQuality } from './image.util'

const baseUrl = getBaseUrl()
const DEFAULT_TITLE = 'MR MARVIS'
const DEFAULT_DESCRIPTION = 'MR MARVIS'
const DEFAULT_IMAGE_WIDTH = 1200
const DEFAULT_IMAGE_HEIGHT = 627
const DEFAULT_IMAGE = `${baseUrl}/social-preview.jpg`

// Default SEO configuration
// @see https://github.com/garmeeh/next-seo
export const seo: DefaultSeoProps = {
  titleTemplate: `%s | ${DEFAULT_TITLE}`,
  defaultTitle: DEFAULT_TITLE,
  description: DEFAULT_DESCRIPTION,
  openGraph: {
    type: 'website',
    locale: 'en_IE', // EN with €
    url: baseUrl,
    siteName: DEFAULT_TITLE,
    description: DEFAULT_DESCRIPTION,
  },
}

/**
 * Extract SEO info out of Sanity data object to hydrate next-seo component.
 */
export const seoData = (seo?: Seo, titleFallback?: string, additionalImages: Image[] = []): NextSeoProps => {
  const image = [seo?.seoImage, ...additionalImages.filter((image) => image._type === 'image')].filter(Boolean)

  return {
    title: seo?.seoTitle || titleFallback,
    description: seo?.seoDescription,
    noindex: seo?.noIndex || false,
    ...(seo?.languageAlternates ? { languageAlternates: seo.languageAlternates } : {}),
    openGraph: {
      url: seo?.seoUrl,
      images:
        image.length > 0
          ? image.map((image) => ({
              // @ts-expect-error - Typescript doesn't know the filtering of the image array above removes the possibility of undefined values.
              url: `${image.url}?q=${imageQuality}`,
              type: image?.url?.endsWith('.png') ? 'image/png' : 'image/jpeg',
              width: image?.width || DEFAULT_IMAGE_WIDTH,
              height: image?.height || DEFAULT_IMAGE_HEIGHT,
              alt: seo?.seoTitle || titleFallback,
            }))
          : [
              {
                url: `${DEFAULT_IMAGE}?q=${imageQuality}`,
                type: 'image/jpg',
                width: DEFAULT_IMAGE_WIDTH,
                height: DEFAULT_IMAGE_HEIGHT,
                alt: seo?.seoTitle || titleFallback,
              },
            ],
    },
    twitter: {
      cardType: 'summary_large_image',
    },
    additionalMetaTags: [
      {
        name: 'twitter:title',
        content: seo?.seoTitle || titleFallback || DEFAULT_TITLE,
      },
      {
        name: 'twitter:description',
        content: seo?.seoDescription || DEFAULT_DESCRIPTION,
      },
      {
        name: 'twitter:image',
        content: image[0]?.url ? `${image[0].url}?q=${imageQuality}` : DEFAULT_IMAGE,
      },
    ],
  }
}

type HreflangLink = {
  rel: 'alternate'
  hrefLang: string | 'x-default'
  href: string
}

type LocalizedSlug = Record<SupportedLocale, Slug>

export const generateHreflangLinks = async (document: SanityDocument): Promise<HreflangLink[]> => {
  const slugData = await fetchLocalizedSlugs(document)

  const links: HreflangLink[] = locales.flatMap((locale) => {
    const slug = coalesceSlug(slugData, locale)
    const url = buildUrl({ ...document, slug: { current: slug } }, { locale })
    return hreflangMapping[locale].map((hrefLang) => ({
      rel: 'alternate',
      hrefLang,
      href: url,
    }))
  })

  // Add x-default link
  const defaultSlug = coalesceSlug(slugData, DEFAULT_LOCALE)
  const defaultUrl = buildUrl({ ...document, slug: { current: defaultSlug } }, { locale: DEFAULT_LOCALE })
  links.push({
    rel: 'alternate',
    hrefLang: 'x-default',
    href: defaultUrl,
  })

  return links
}

const fetchLocalizedSlugs = async (document: SanityDocument): Promise<Slug | LocalizedSlug> => {
  const query = `*[_id == "${document._id}"] { slug }[0]`
  const result = await sanityCdnClient.fetch(query)
  return result?.slug || {}
}

const coalesceSlug = (slugData: Slug | LocalizedSlug, locale: SupportedLocale): string => {
  if ('current' in slugData) {
    // Non-localized slug, use the same slug for all locales
    return slugData.current
  }

  // Handle localized slugs with fallback logic
  const fallbackLocales = localesWithFallbackForCoalesceQuery[locale] || [locale]
  for (const fallbackLocale of fallbackLocales) {
    if (slugData[fallbackLocale as keyof typeof slugData]?.current) {
      return slugData[fallbackLocale as keyof typeof slugData].current
    }
  }
  return ''
}

export const findSeoUrlForLocale = (languageAlternates: HreflangLink[], locale: SupportedLocale): string | undefined => {
  const currentHreflang = languageAlternates?.find((link) => link.hrefLang === locale || link.hrefLang.includes(locale))
  return currentHreflang?.href
}
