import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'

export const SectionActivateAccountSchema = z.object({
  _id: z.string(),
  _type: z.literal('sectionActivateAccount'),
  title: z.string(),
  subtitle: z.string().optional(),
  backgroundImage: MediaSchema,
  passwordLabel: z.string(),
  passwordErrorMessage: z.string(),
  confirmPasswordLabel: z.string(),
  passwordMatchErrorMessage: z.string(),
  submitButtonText: z.string(),
})

export type SectionActivateAccountData = z.infer<typeof SectionActivateAccountSchema>

export const SECTION_ACTIVATE_ACCOUNT_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionActivateAccount' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    defined(subtitle) => {
      ${coalesceQuery('subtitle', locale)}
    },
    backgroundImage -> {
      ${MEDIA_FRAGMENT()}
    },
    ${coalesceQuery('passwordLabel', locale)},
    ${coalesceQuery('passwordErrorMessage', locale)},
    ${coalesceQuery('confirmPasswordLabel', locale)},
    ${coalesceQuery('passwordMatchErrorMessage', locale)},
    ${coalesceQuery('submitButtonText', locale)},
  }
`)
