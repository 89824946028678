import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceFilter, coalesceLocalizedValue } from '../../utils'
import { RichTextSchema } from './richText.fragment'

export const EmissionsContributorsSchema = z.object({
  rawMaterialsTitle: z.string(),
  rawMaterialsDescription: z.string(),
  manufacturingTitle: z.string(),
  manufacturingDescription: z.string(),
  packagingTitle: z.string(),
  packagingDescription: z.string(),
  distributionTitle: z.string(),
  distributionDescription: z.string(),
  productUseTitle: z.string(),
  productUseDescription: z.string(),
  endOfLifeTitle: z.string(),
  endOfLifeDescription: z.string(),
})

export type EmissionsContributors = z.infer<typeof EmissionsContributorsSchema>
export const SustainabilityInformationSchema = z.object({
  sustainabilityCommitmentStatement: z.string(),
  carbonEmissions: z.string(),
  emissionsContributors: EmissionsContributorsSchema,
  sustainabilityHighlights: z.object({
    locallyMadeText: z.string(),
    organicText: z.string(),
    recycledText: z.string(),
    mulesingFreeText: z.string(),
  }),
  calculationMethodCta: z.string(),
  calculationMethodText: RichTextSchema,
  projectTitle: z.string(),
  projectDescription: RichTextSchema,
})

export type SustainabilityInformation = z.infer<typeof SustainabilityInformationSchema>

export const SUSTAINABILITY_INFORMATION_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  *[_id == "sustainabilityInformation"][0] {
    ${coalesceLocalizedValue('sustainabilityCommitmentStatement', locale)},
    ${coalesceLocalizedValue('carbonEmissions', locale)},
    emissionsContributors {
      ${coalesceLocalizedValue('rawMaterialsTitle', locale)},
      ${coalesceLocalizedValue('rawMaterialsDescription', locale)},
      ${coalesceLocalizedValue('manufacturingTitle', locale)},
      ${coalesceLocalizedValue('manufacturingDescription', locale)},
      ${coalesceLocalizedValue('packagingTitle', locale)},
      ${coalesceLocalizedValue('packagingDescription', locale)},
      ${coalesceLocalizedValue('distributionTitle', locale)},
      ${coalesceLocalizedValue('distributionDescription', locale)},
      ${coalesceLocalizedValue('productUseTitle', locale)},
      ${coalesceLocalizedValue('productUseDescription', locale)},
      ${coalesceLocalizedValue('endOfLifeTitle', locale)},
      ${coalesceLocalizedValue('endOfLifeDescription', locale)},
    },
    sustainabilityHighlights {
      ${coalesceLocalizedValue('locallyMadeText', locale)},
      ${coalesceLocalizedValue('organicText', locale)},
      ${coalesceLocalizedValue('recycledText', locale)},
      ${coalesceLocalizedValue('mulesingFreeText', locale)},
    },
    ${coalesceLocalizedValue('calculationMethodCta', locale)},
    ${coalesceLocalizedValue(
      'calculationMethodText',
      locale,
      '',
      `[] {
            ...,
            markDefs[] {
              ...,
              _type == "internalLink" => {
                // We used coalesce here because Products don't have a localized slug
                'slug': coalesce(${coalesceFilter('@.reference->slug', locale)}, @.reference->slug),
                "type": @.reference->_type
              }
            }
          }`,
    )},
    ${coalesceLocalizedValue('projectTitle', locale)},
    ${coalesceLocalizedValue(
      'projectDescription',
      locale,
      '',
      `[] {
            ...,
            markDefs[] {
              ...,
              _type == "internalLink" => {
                // We used coalesce here because Products don't have a localized slug
                'slug': coalesce(${coalesceFilter('@.reference->slug', locale)}, @.reference->slug),
                "type": @.reference->_type
              }
            }
          }`,
    )},
  }
`)
