import Cookies from 'js-cookie'
import posthog from 'posthog-js'
import { InsightsEvent } from 'search-insights'

import { TelemetryEvent } from '@/types/telemetry'

import { elevarSessionId, elevarUserId } from './elevar.util'
import { isBrowser } from './window.util'

// Our own telemetry events; prefixed with 'mm_'
export enum TELEMETRY_DATA_LAYER_EVENTS {
  CONSENT_SET = 'mm_consent_set',
  PAGE_VIEW = 'mm_page_view',
  PAGE_VIEW_DELAYED = 'mm_page_view_delayed',
  ASK_COOKIE_CONSENT = 'mm_ask_cookie_consent',
  ACCEPT_ALL_COOKIES = 'mm_accept_all_cookies',
  ERROR_PAGE = 'mm_error_page',
  WEB_VITALS = 'mm_web_vitals',
  VIEW_ITEM_LIST = 'mm_view_item_list',
  VIEW_SHOP_THE_LOOK = 'mm_view_shop_the_look',
  VIEW_RETAIL_INVENTORY = 'mm_view_retail_inventory',
  LOGOUT = 'mm_logout',
  OUT_OF_STOCK_SIGNUP = 'mm_out_of_stock_sign_up',
  START_CHECKOUT = 'mm_start_checkout',
  SIZE_GUIDE_RECOMMENDATION = 'mm_size_guide_recommendation',
  SIZE_GUIDE_ADD_TO_CART = 'mm_size_guide_add_to_cart',
  SIZE_GUIDE_OPENED = 'mm_size_guide_opened',
  CLICK_TOGGLE_FILTERS = 'mm_click_toggle_filters',
  CLICK_CLEAR_ALL_FILTERS = 'mm_click_clear_all_filters',
  CLICK_SORT = 'mm_click_sort',
  CLICK_FILTER = 'mm_click_filter',
  CLICK_CATEGORY_IMAGE = 'mm_click_category_image',
  CLICK_CATEGORY_PILL = 'mm_click_category_pill',
  CLICK_GRID_CALLOUT = 'mm_click_grid_callout',
  CLICK_HIGHLIGHT_ITEM = 'mm_click_highlight_item',
  SEARCH = 'mm_search',
  OPEN_NAVIGATION = 'mm_open_navigation',
  CLICK_NAVIGATION_LINK = 'mm_click_navigation_link',
  CLICK_NAVIGATION_CLOSE = 'mm_click_navigation_close',
  CLICK_NAVIGATION_BREADCRUMBS = 'mm_click_navigation_breadcrumbs',
  CLICK_NAVIGATION_BACK = 'mm_click_navigation_back',
  CLICK_UPSELL_BANNER = 'mm_click_upsell_banner',
}

export enum NAVIGATION_LINK_VARIANTS {
  LINK = 'link',
  TRIGGER = 'trigger',
  CARD = 'card',
  CTA = 'cta',
  COLLECTION_IMAGE = 'collection-image',
}

// Elevar events; prefixed with 'dl_'
export enum ELEVAR_DATA_LAYER_EVENTS {
  DL_USER_DATA = 'dl_user_data',
  DL_SIGN_UP = 'dl_sign_up',
  DL_LOGIN = 'dl_login',
  DL_VIEW_ITEM_LIST = 'dl_view_item_list', // Chunked as to reduce Elevar event payload
  DL_VIEW_SEARCH_RESULTS = 'dl_view_search_results',
  DL_SELECT_ITEM = 'dl_select_item',
  DL_VIEW_ITEM = 'dl_view_item',
  DL_ADD_TO_CART = 'dl_add_to_cart',
  DL_REMOVE_FROM_CART = 'dl_remove_from_cart',
  DL_VIEW_CART = 'dl_view_cart',
  DL_SUBSCRIBE = 'dl_subscribe',
}

// Event labels; convention is to capitalize each word (e.g. 'Accepted Cookies') and use past tense.
const eventLabels: Record<ELEVAR_DATA_LAYER_EVENTS | TELEMETRY_DATA_LAYER_EVENTS, string> = {
  [TELEMETRY_DATA_LAYER_EVENTS.CONSENT_SET]: 'Consent Set',
  [TELEMETRY_DATA_LAYER_EVENTS.PAGE_VIEW]: 'Page Viewed',
  [TELEMETRY_DATA_LAYER_EVENTS.PAGE_VIEW_DELAYED]: 'Page Viewed (delayed)',
  [TELEMETRY_DATA_LAYER_EVENTS.ASK_COOKIE_CONSENT]: 'Asked For Consent',
  [TELEMETRY_DATA_LAYER_EVENTS.ACCEPT_ALL_COOKIES]: 'Accepted Cookies',
  [TELEMETRY_DATA_LAYER_EVENTS.ERROR_PAGE]: 'Error Page Viewed',
  [TELEMETRY_DATA_LAYER_EVENTS.WEB_VITALS]: 'Web Vitals Determined',
  [TELEMETRY_DATA_LAYER_EVENTS.VIEW_ITEM_LIST]: 'Product List Viewed',
  [TELEMETRY_DATA_LAYER_EVENTS.VIEW_SHOP_THE_LOOK]: 'Shop The Look Viewed',
  [TELEMETRY_DATA_LAYER_EVENTS.VIEW_RETAIL_INVENTORY]: 'Retail Inventory Viewed',
  [TELEMETRY_DATA_LAYER_EVENTS.LOGOUT]: 'Logged Out',
  [TELEMETRY_DATA_LAYER_EVENTS.OUT_OF_STOCK_SIGNUP]: 'Out of Stock Sign Up',
  [TELEMETRY_DATA_LAYER_EVENTS.START_CHECKOUT]: 'Started Checkout',
  [TELEMETRY_DATA_LAYER_EVENTS.SIZE_GUIDE_RECOMMENDATION]: 'Size Guide Recommendation',
  [TELEMETRY_DATA_LAYER_EVENTS.SIZE_GUIDE_ADD_TO_CART]: 'Size Guide Add To Cart',
  [TELEMETRY_DATA_LAYER_EVENTS.SIZE_GUIDE_OPENED]: 'Size Guide Opened',
  [TELEMETRY_DATA_LAYER_EVENTS.CLICK_TOGGLE_FILTERS]: 'Toggle Filter Clicked',
  [TELEMETRY_DATA_LAYER_EVENTS.CLICK_CLEAR_ALL_FILTERS]: 'Clear All Filters Clicked',
  [TELEMETRY_DATA_LAYER_EVENTS.CLICK_SORT]: 'Sort Clicked',
  [TELEMETRY_DATA_LAYER_EVENTS.CLICK_FILTER]: 'Filter Clicked',
  [TELEMETRY_DATA_LAYER_EVENTS.CLICK_CATEGORY_IMAGE]: 'Category Image Clicked',
  [TELEMETRY_DATA_LAYER_EVENTS.CLICK_CATEGORY_PILL]: 'Category Pill Clicked',
  [TELEMETRY_DATA_LAYER_EVENTS.CLICK_GRID_CALLOUT]: 'Grid Callout Clicked',
  [TELEMETRY_DATA_LAYER_EVENTS.CLICK_HIGHLIGHT_ITEM]: 'Highlight Item Clicked',
  [TELEMETRY_DATA_LAYER_EVENTS.SEARCH]: 'Search',
  [TELEMETRY_DATA_LAYER_EVENTS.OPEN_NAVIGATION]: 'Navigation Opened',
  [TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK]: 'Navigation Link Clicked',
  [TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_CLOSE]: 'Navigation Close Clicked',
  [TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_BREADCRUMBS]: 'Navigation Breadcrumbs Clicked',
  [TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_BACK]: 'Navigation Back Clicked',
  [TELEMETRY_DATA_LAYER_EVENTS.CLICK_UPSELL_BANNER]: 'Upsell Banner Clicked',
  [ELEVAR_DATA_LAYER_EVENTS.DL_USER_DATA]: 'User Data Set',
  [ELEVAR_DATA_LAYER_EVENTS.DL_SIGN_UP]: 'Signed Up',
  [ELEVAR_DATA_LAYER_EVENTS.DL_LOGIN]: 'Logged In',
  [ELEVAR_DATA_LAYER_EVENTS.DL_VIEW_ITEM_LIST]: 'Product List Viewed (chunked)',
  [ELEVAR_DATA_LAYER_EVENTS.DL_VIEW_SEARCH_RESULTS]: 'Products Searched',
  [ELEVAR_DATA_LAYER_EVENTS.DL_SELECT_ITEM]: 'Product Clicked',
  [ELEVAR_DATA_LAYER_EVENTS.DL_VIEW_ITEM]: 'Product Viewed',
  [ELEVAR_DATA_LAYER_EVENTS.DL_ADD_TO_CART]: 'Product Added',
  [ELEVAR_DATA_LAYER_EVENTS.DL_REMOVE_FROM_CART]: 'Product Removed',
  [ELEVAR_DATA_LAYER_EVENTS.DL_VIEW_CART]: 'Cart Viewed',
  [ELEVAR_DATA_LAYER_EVENTS.DL_SUBSCRIBE]: 'Subscribed To Newsletter',
}

// Posthog web analytics allow list
const posthogAnalyticsEventAllowList: TelemetryEvent[] = [
  TELEMETRY_DATA_LAYER_EVENTS.ACCEPT_ALL_COOKIES,
  TELEMETRY_DATA_LAYER_EVENTS.VIEW_ITEM_LIST,
  TELEMETRY_DATA_LAYER_EVENTS.OUT_OF_STOCK_SIGNUP,
  TELEMETRY_DATA_LAYER_EVENTS.LOGOUT,
  TELEMETRY_DATA_LAYER_EVENTS.START_CHECKOUT,
  TELEMETRY_DATA_LAYER_EVENTS.SIZE_GUIDE_RECOMMENDATION,
  TELEMETRY_DATA_LAYER_EVENTS.SIZE_GUIDE_ADD_TO_CART,
  TELEMETRY_DATA_LAYER_EVENTS.SEARCH,
  ELEVAR_DATA_LAYER_EVENTS.DL_SIGN_UP,
  ELEVAR_DATA_LAYER_EVENTS.DL_LOGIN,
  ELEVAR_DATA_LAYER_EVENTS.DL_VIEW_SEARCH_RESULTS,
  ELEVAR_DATA_LAYER_EVENTS.DL_SELECT_ITEM,
  ELEVAR_DATA_LAYER_EVENTS.DL_VIEW_ITEM,
  ELEVAR_DATA_LAYER_EVENTS.DL_ADD_TO_CART,
  ELEVAR_DATA_LAYER_EVENTS.DL_REMOVE_FROM_CART,
  ELEVAR_DATA_LAYER_EVENTS.DL_VIEW_CART,
  ELEVAR_DATA_LAYER_EVENTS.DL_SUBSCRIBE,
  TELEMETRY_DATA_LAYER_EVENTS.VIEW_RETAIL_INVENTORY,
  TELEMETRY_DATA_LAYER_EVENTS.CLICK_TOGGLE_FILTERS,
  TELEMETRY_DATA_LAYER_EVENTS.CLICK_CLEAR_ALL_FILTERS,
  TELEMETRY_DATA_LAYER_EVENTS.CLICK_SORT,
  TELEMETRY_DATA_LAYER_EVENTS.CLICK_FILTER,
  TELEMETRY_DATA_LAYER_EVENTS.CLICK_CATEGORY_IMAGE,
  TELEMETRY_DATA_LAYER_EVENTS.CLICK_CATEGORY_PILL,
  TELEMETRY_DATA_LAYER_EVENTS.CLICK_GRID_CALLOUT,
  TELEMETRY_DATA_LAYER_EVENTS.CLICK_HIGHLIGHT_ITEM,
  TELEMETRY_DATA_LAYER_EVENTS.OPEN_NAVIGATION,
  TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK,
  TELEMETRY_DATA_LAYER_EVENTS.VIEW_SHOP_THE_LOOK,
  TELEMETRY_DATA_LAYER_EVENTS.SIZE_GUIDE_OPENED,
  TELEMETRY_DATA_LAYER_EVENTS.CLICK_UPSELL_BANNER,
]

const elevarEventValues = Object.values<any>(ELEVAR_DATA_LAYER_EVENTS)

export const trackEvent = (event: TelemetryEvent, eventData: Record<string, any> | InsightsEvent = {}) => {
  if (!isBrowser) {
    return
  }

  try {
    const label: string | undefined = (eventLabels as Record<string, string>)?.[event]
    const data: Readonly<Record<string, any>> = { event, label, ...eventData }
    const isElevarEvent = elevarEventValues.includes(event)
    const isOfficeRequest = Cookies.get('isOfficeRequest') === 'true'

    if (process.env.NODE_ENV === 'production') {
      // Elevar/GTM
      // @see https://docs.getelevar.com/
      // @see https://elevar.notion.site/3-9-Elevar-Headless-Onboarding-Guide-35cbf3f0adb94d8e96b8a7946a227979
      if (isElevarEvent) {
        // Use Elevar library to push event; Elevar will in turn still
        // send it into the GTM data layer (just via their integration)
        window.ElevarDataLayer = window.ElevarDataLayer ?? []
        window.ElevarDataLayer.push(data)
      } else {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(data)
      }

      // Posthog analytics
      // @see https://posthog.com/docs/libraries/js
      if (posthogAnalyticsEventAllowList.includes(event) && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
        posthog?.capture(label || event, {
          ...data,
          // Use $set to set Elevar user and session ID persistently
          // @see https://posthog.com/docs/libraries/js#setting-user-properties
          $set: {
            isOfficeRequest,
            elevar_user_id: elevarUserId(),
            elevar_session_id: elevarSessionId(),
          },
        })
      }
    } else if (process.env.NODE_ENV === 'development') {
      console.debug(`Track ${data?.event || ''} event.`, data)
    }
  } catch (error: unknown) {
    // Silent failure; telemetry should NEVER impact the user negatively
    if (process.env.NODE_ENV === 'development') {
      console.error(error)
    }
  }
}

/**
 * Init Posthog analytics.
 *
 * @see https://posthog.com/docs/libraries/next-js
 */
export const initPosthog = () => {
  if (!isBrowser || !process.env.NEXT_PUBLIC_POSTHOG_KEY) {
    return
  }

  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    autocapture: false,
    disable_session_recording: true,
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://eu.posthog.com',
    // @see https://posthog.com/docs/session-replay/privacy
    session_recording: {
      maskAllInputs: true,
      maskTextFn: (text, element) => {
        if (element?.dataset['record-posthog'] === 'true') {
          return text
        }
        return '*'.repeat(text.trim().length)
      },
    },
    loaded: (posthog) => {
      posthog.debug(process.env.NEXT_PUBLIC_POSTHOG_DEBUG === 'true')

      // Session recording for 1% of sessions
      if (process.env.NODE_ENV === 'production' && Math.random() < 0.01) {
        posthog.startSessionRecording({ sampling: false })
      }
    },
  })
}

export const capturedPostHogFeatureViews: string[] = []
