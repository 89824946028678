import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { LINK_FRAGMENT, LinkSchema } from '../components/link.fragment'

export const SectionNavigationSchema = z.object({
  _type: z.literal('sectionNavigation'),
  _id: z.string(),
  navigationItems: z.array(LinkSchema),
})

export type SectionNavigationData = z.infer<typeof SectionNavigationSchema>

export const SECTION_NAVIGATION_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionNavigation' => {
    _type,
    _id,
    navigationItems[]-> {
      ${LINK_FRAGMENT(locale)}
    }
  }
`)
