import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'

export const SectionSizeChartsSchema = z.object({
  _type: z.literal('sectionSizeCharts'),
  _id: z.string(),
  productTypes: z.array(z.enum(['trousers', 'shorts', 'tops', 'shoes', 'other'])),
})

export type SectionSizeChartsData = z.infer<typeof SectionSizeChartsSchema>

export const SECTION_SIZE_CHART_FRAGMENT = () =>
  defineQuery(`
  _type == 'sectionSizeCharts' => {
    _type,
    _id,
    "productTypes": array::unique(*[_type == 'collection' && isPrimary == true && sizes != null && showInLocales[$locale] != false].productType)
  }
`)
