import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { CTA_FRAGMENT, CtaSchema } from '../components/cta.fragment'

export const SectionErrorSchema = z.object({
  _type: z.literal('sectionError'),
  _id: z.string(),
  title: z.string().optional(),
  description: z.string().optional(),
  cta: CtaSchema.optional(),
})

export type SectionErrorData = z.infer<typeof SectionErrorSchema>

export const SECTION_ERROR_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionError' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    ${coalesceQuery('description', locale)},
    defined(cta) => {
      cta-> {
        ${CTA_FRAGMENT(locale)}
      }
    },
  }
`)
