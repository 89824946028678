/**
 * Array.at() isn't supported yet by all browsers, hence this function.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/at
 */
export const at = <T = unknown>(array: Array<T>, index: number) => {
  const length = array == null ? 0 : array?.length

  if (!length) {
    return undefined
  }

  index += index < 0 ? length : 0

  return array?.[index]
}
