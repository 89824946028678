import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { CTA_FRAGMENT, CtaSchema } from '../components/cta.fragment'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'

export const SectionTextMediaCtaSchema = z.object({
  _type: z.literal('sectionTextMediaCta'),
  _id: z.string(),
  name: z.string(),
  title: z.string(),
  text: RichTextSchema.optional(),
  copyPositionDesktop: z.enum(['left', 'right']),
  media: MediaSchema,
  cta: CtaSchema.optional(),
})

export type SectionTextMediaCtaData = z.infer<typeof SectionTextMediaCtaSchema>

export const SECTION_TEXT_MEDIA_CTA_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionTextMediaCta' => {
    _type,
    _id,
    name,
    ${coalesceQuery('title', locale)},
    defined(text) => {
      ${RICH_TEXT_FRAGMENT(locale, 'text')},
    },
    copyPositionDesktop,
    media-> {
      ${MEDIA_FRAGMENT()}
    },
    defined(cta) => {
      cta-> {
        ${CTA_FRAGMENT(locale)}
      }
    }
  }
`)
