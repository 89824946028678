import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SlugSchema } from '../../types'
import { coalesceQuery } from '../../utils'

export const SectionSearchResultsSchema = z.object({
  _type: z.literal('sectionSearchResults'),
  _id: z.string(),
  searchResultsSlug: SlugSchema,
})

export type SectionSearchResultsData = z.infer<typeof SectionSearchResultsSchema>

export const SECTION_SEARCH_RESULTS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionSearchResults' => {
    _type,
    _id,
    "searchResultsSlug": *[_type == 'pageSearch'][0] {
      ${coalesceQuery('slug', locale)},
    },
  }
`)
