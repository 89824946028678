import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { LINK_FRAGMENT, LinkSchema } from '../components/link.fragment'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'

export const SectionForgotPasswordSchema = z.object({
  _type: z.literal('sectionForgotPassword'),
  _id: z.string(),
  title: z.string(),
  subtitle: z.string().optional(),
  backgroundImage: MediaSchema,
  emailLabel: z.string(),
  emailErrorMessage: z.string(),
  submitSuccessTitle: z.string(),
  submitSuccessSubtitle: z.string().optional(),
  submitButtonText: z.string(),
  returnToLoginLink: LinkSchema,
})

export type SectionForgotPasswordData = z.infer<typeof SectionForgotPasswordSchema>

export const SECTION_FORGOT_PASSWORD_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionForgotPassword' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    defined(subtitle) => {
      ${coalesceQuery('subtitle', locale)}
    },
    backgroundImage -> {
      ${MEDIA_FRAGMENT()}
    },
    ${coalesceQuery('emailLabel', locale)},
    ${coalesceQuery('emailErrorMessage', locale)},
    ${coalesceQuery('submitSuccessTitle', locale)},
    defined(submitSuccessSubtitle) => {
      ${coalesceQuery('submitSuccessSubtitle', locale)}
    },
    ${coalesceQuery('submitButtonText', locale)},
    returnToLoginLink-> {
      ${LINK_FRAGMENT(locale)}
    }
  }
`)
