import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { ArticleSchema, RELATED_ARTICLES_FRAGMENT } from '../components/relatedArticles.fragments'

export const SectionArticleOverviewSchema = z.object({
  _type: z.literal('sectionArticleOverview'),
  _id: z.string(),
  articles: z.array(ArticleSchema),
  numberOfArticles: z.number(),
})

export type SectionArticleOverviewData = z.infer<typeof SectionArticleOverviewSchema>

export const SECTION_ARTICLE_OVERVIEW_FRAGMENT = (locale = DEFAULT_LOCALE, slug = '1') => {
  const paginateFrom = !Number.isNaN(parseInt(slug)) ? (parseInt(slug) - 1) * 9 : 0
  const paginateTo = !Number.isNaN(parseInt(slug)) ? parseInt(slug) * 9 : 9

  return defineQuery(`
    _type == 'sectionArticleOverview' => {
      _type,
      _id,
      "articles" : *[_type in ['pageArticle', 'pagePressRelease'] && _id in ^.articles[]._ref && showInLocales.[$locale] != false] | order(dateTime(dateCreated) desc)[${paginateFrom}...${paginateTo}] {
        ${RELATED_ARTICLES_FRAGMENT(locale)}
      },
      "numberOfArticles": count(*[_type in ['pageArticle', 'pagePressRelease'] && _id in ^.articles[]._ref && showInLocales[$locale] != false])
    }
  `)
}
