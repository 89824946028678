import { defineQuery } from 'next-sanity'
import { z } from 'zod'

export const ImageSchema = z.object({
  _type: z.literal('image'),
  url: z.string(),
  originalFilename: z.string(),
  lqip: z.string(),
  width: z.number(),
  height: z.number(),
  crop: z
    .object({
      top: z.number(),
      bottom: z.number(),
      left: z.number(),
      right: z.number(),
    })
    .optional(),
  hotspot: z
    .object({
      height: z.number(),
      x: z.number(),
      y: z.number(),
    })
    .optional(),
  asset: z
    .object({
      _ref: z.string(),
    })
    .optional(), // SanityReference type is not defined in zod, so using z.any()
  isOpaque: z.boolean().optional(),
})

export type Image = z.infer<typeof ImageSchema>

export const GET_IMAGE_FRAGMENT = () =>
  defineQuery(`
  _type,
  asset,
  ...asset-> {
    url,
    originalFilename,
    ...metadata {
      lqip,
      isOpaque,
      ...dimensions {
        width,
        height
      }
    }
  },
  defined(crop) => {
    crop {
      top,
      bottom,
      left,
      right
    }
  },
  defined(hotspot) => {
    hotspot {
      height,
      x,
      y
    }
  }
`)
