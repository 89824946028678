import { defineQuery } from 'next-sanity'

import { GET_IMAGE_FRAGMENT } from './getImage.fragment'

type PhotoType =
  // Packshots
  | 'PCK-F1' // front packshot
  | 'PCK-GIF' // packshot gif

  // Model photos
  | 'MDL-F1' // front
  | 'MDL-F2' // front with angle/pose full length
  | 'MDL-F3' // different pose cropped
  | 'MDL-B1' // back
  | 'MDL-F4' // front with angle cropped
  | 'MDL-CF1' // close up front
  | 'MDL-CF2' // close up front with angle/pose

  // Detail photos
  | 'DET-FF' // front full
  | 'DET-S1' // side 1
  | 'DET-FH' // front half
  | 'DET-BF' // back full
  | 'DET-SF' // side full
  | 'DET-OG' // Octagon
  | 'DET-PT' // pocket
  | 'DET-BPT' // backpocket

  // Feature photos
  | 'FTL-HPT' // hidden pocket
  | 'FTL-EW' // elatiscated waistband
  | 'FTL-FY' // fly
  | 'FTL-OG' // octagon detail
  | 'FTL-MM' // mr marvis tag
  | 'FTL-SF' // side full
  | 'FTL-NQ' // name and quote
  | 'FTL-ST' // string
  | 'FTL-SL' // size label

  // Extra
  | 'EXT_HID' // hidden extra photo (used for FB)

export const PRODUCT_IMAGE_TYPE_FRAGMENT = (photoType: PhotoType) =>
  defineQuery(`
    images[_type == 'image' && upper(asset->.originalFilename) match '*${photoType}*'][0] {
      _type == 'image' => {
        ${GET_IMAGE_FRAGMENT()}
      }
    }
`)
