import { getBaseUrl } from './baseUrl'

/**
 * The site URL
 */
export const siteUrl = getBaseUrl()

/**
 * Get the site URL
 * @param path - The path to append to the site URL
 * @returns The site URL
 */
export const getSiteUrl = (path = '/'): Readonly<string> => {
  if (!path) return `${siteUrl}/`
  const normalizedPath = path.startsWith('/') ? path : `/${path}`
  return `${siteUrl}${normalizedPath}`
}
