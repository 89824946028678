/**
 * Return whether 2 string arrays are equal, comparing constituents.
 */
export const equals = (a: string[], b: string[]): boolean => {
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return false
  }

  // Since sort happens in place, we need to make a copy
  const aCopy = [...a].sort()
  const bCopy = [...b].sort()

  return aCopy.length === bCopy.length && aCopy.every((val, index) => val === bCopy[index])
}
