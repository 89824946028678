import { Algoliasearch, algoliasearch } from 'algoliasearch'
import { liteClient, LiteClient } from 'algoliasearch/lite'

import { AlgoliaObjectType } from './types'

/**
 * Suffixes for Algolia indexes.
 */
export const INDEX_SUFFIXES = ['bestsellers', 'newest', 'price_asc', 'price_desc'] as const

/**
 * Type for the suffixes of Algolia indexes.
 */
export type AlgoliaIndexSuffixes = (typeof INDEX_SUFFIXES)[number]

/**
 * Checks if an index name ends with a valid suffix.
 * @param indexName The index name to check.
 * @returns True if the index name ends with a valid suffix, false otherwise.
 */
export const hasValidSuffix = (indexName: string): boolean => {
  return INDEX_SUFFIXES.some((suffix) => indexName.endsWith(suffix))
}

/**
 * Returns an Algolia client.
 * Make sure to set the Algolia credentials in the .env file of your app running this code.
 */
export const algoliaClient = (): Algoliasearch => {
  if (process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID || process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY) {
    return algoliasearch(process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID || '', process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || '')
  }
  return {} as Algoliasearch
}

/**
 * Returns an Algolia Lite client.
 */
export let algoliaLiteClient: LiteClient = {} as LiteClient

if (process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID && process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY) {
  algoliaLiteClient = liteClient(process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID || '', process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || '')
}

/**
 * Patched searchclient to prevent Algolia search for empty queries but rather return an empty state.
 * This improves performance when opening or resetting the search
 */
export const searchClient: LiteClient = {
  ...algoliaLiteClient,
  search(requests) {
    if (Array.isArray(requests) && requests.every(({ params }) => !params?.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: '',
        })),
      })
    }

    return algoliaLiteClient.search(requests)
  },
}

/**
/**
 * Returns the name of the Algolia index for a given locale.
 * @param locale The locale to get the index name for.
 * @returns The name of the Algolia index.
 */
export const getAlgoliaIndexName = (locale: string, sortBy?: string): string => {
  // if (process.env.NODE_ENV === 'development') {
  //   return `dev_search_en`
  // }

  const baseIndex = `production_search_${locale}`

  // If no sortBy is provided or it's 'relevance', return base index
  if (!sortBy || sortBy === 'relevance') {
    return baseIndex
  }

  // Check if the sortBy already includes a valid suffix
  const suffix = INDEX_SUFFIXES.find((s) => sortBy.endsWith(s))
  if (!suffix) {
    return baseIndex
  }

  // Ensure the baseIndex does not already end with the suffix
  if (baseIndex.endsWith(suffix)) {
    return baseIndex
  }

  return `${baseIndex}_${suffix}`
}

/**
 * Builds an object ID for Algolia.
 * @param type: The type of the object
 * @param id: The ID of the object
 * @param suffix: The suffix of the object. Used for multiple variants of the same product.
 * @returns The object ID
 */
export const buildObjectID = (type: AlgoliaObjectType, id = '', suffix = '') => {
  let objectID = `${type}-${id}`
  if (suffix) {
    objectID += `-${suffix}`
  }
  return objectID
}
