import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { GET_IMAGE_FRAGMENT, ImageSchema } from '../common/getImage.fragment'

export const CareInstructionSchema = z.object({
  instruction: z.string(),
  type: z.enum(['washing', 'drying', 'ironing', 'dryCleaning', 'wearAndTear']),
  icon: ImageSchema,
})

export type CareInstruction = z.infer<typeof CareInstructionSchema>

const CARE_INSTRUCTIONS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  ${coalesceQuery('instruction', locale)},
  type,
  icon {
    ${GET_IMAGE_FRAGMENT()}
  }
`)

export const CareInstructionSetSchema = z.object({
  washing: z.object({
    careInstructions: z.array(CareInstructionSchema),
  }),
  drying: z.object({
    careInstructions: z.array(CareInstructionSchema),
  }),
  ironing: z.object({
    careInstructions: z.array(CareInstructionSchema),
  }),
  storage: z.object({
    careInstructions: z.array(CareInstructionSchema),
  }),
  wearAndTear: z.object({
    careInstructions: z.array(CareInstructionSchema),
  }),
})

export type CareInstructionSet = z.infer<typeof CareInstructionSetSchema>

export const CARE_INSTRUCTION_SET_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  washing {
    careInstructions[]-> {
      ${CARE_INSTRUCTIONS_FRAGMENT(locale)}
    }
  },
  drying {
    careInstructions[]-> {
      ${CARE_INSTRUCTIONS_FRAGMENT(locale)}
    }
  },
  ironing {
    careInstructions[]-> {
      ${CARE_INSTRUCTIONS_FRAGMENT(locale)}
    }
  },
  storage {
    careInstructions[]-> {
      ${CARE_INSTRUCTIONS_FRAGMENT(locale)}
    }
  },
  wearAndTear {
    careInstructions[]-> {
      ${CARE_INSTRUCTIONS_FRAGMENT(locale)}
    }
  }
`)
