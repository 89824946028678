import { createContext, FunctionComponent, PropsWithChildren, useContext, useMemo, useReducer } from 'react'

import { ProductSpotProduct } from 'data-access/sanity/fragments/components/hotspotImage.fragment'

type UIContextContextType = {
  displayCart: boolean
  displayMobileMenu: boolean
  showQuickAddToCart: string | boolean
  wishlistCount: number | null
  displayRedirectNotification: boolean
  displayFilters: boolean
  displayMobileFilters: boolean
  filtersActive: boolean
  pcpResultsLoaded: boolean
  displayShopTheLook: {
    open: boolean
    products: ProductSpotProduct[] | []
  }
  displaySearchUI: boolean
  openCart: () => void
  closeCart: () => void
  openMobileMenu: () => void
  closeMobileMenu: () => void
  updateWishlistCount: (payload?: number) => void
  setShowQuickAddToCart: (payload: string | boolean) => void
  openRedirectNotification: () => void
  closeRedirectNotification: () => void
  openShopTheLook: (payload: ProductSpotProduct[]) => void
  closeShopTheLook: () => void
  openFilters: () => void
  closeFilters: () => void
  toggleFilters: () => void
  toggleMobileFilters: () => void
  toggleMobileMenuSearchUI: () => void
  showSearchUI: () => void
  hideSearchUI: () => void
  toggleFiltersActive: (payload: boolean) => void
  setPcpResultsLoaded: (payload: boolean) => void
}

const initialState: UIContextContextType = {
  displayCart: false,
  displayMobileMenu: false,
  showQuickAddToCart: false,
  wishlistCount: null,
  displayRedirectNotification: false,
  displayShopTheLook: {
    open: false,
    products: [],
  },
  displayFilters: true,
  displayMobileFilters: false,
  displaySearchUI: false,
  toggleMobileMenuSearchUI: () => {},
  filtersActive: false,
  pcpResultsLoaded: false,
  openCart: () => {},
  closeCart: () => {},
  openMobileMenu: () => {},
  closeMobileMenu: () => {},
  updateWishlistCount: () => {},
  setShowQuickAddToCart: () => {},
  openRedirectNotification: () => {},
  closeRedirectNotification: () => {},
  openShopTheLook: () => {},
  closeShopTheLook: () => {},
  openFilters: () => {},
  closeFilters: () => {},
  toggleFilters: () => {},
  toggleMobileFilters: () => {},
  showSearchUI: () => {},
  hideSearchUI: () => {},
  toggleFiltersActive: () => {},
  setPcpResultsLoaded: () => {},
}

export const UIContext = createContext<UIContextContextType>(initialState)

function uiReducer(state: any, action: any) {
  switch (action.type) {
    case 'OPEN_CART': {
      return {
        ...state,
        displayCart: true,
      }
    }
    case 'CLOSE_CART': {
      return {
        ...state,
        displayCart: false,
      }
    }
    case 'OPEN_MOBILE_MENU': {
      return {
        ...state,
        displayMobileMenu: true,
      }
    }
    case 'CLOSE_MOBILE_MENU': {
      return {
        ...state,
        displayMobileMenu: false,
      }
    }
    case 'UPDATE_WISHLIST_COUNT': {
      return {
        ...state,
        wishlistCount: action.payload,
      }
    }
    case 'SET_QUICK_ADD_TO_CART': {
      return {
        ...state,
        showQuickAddToCart: action.payload,
      }
    }
    case 'OPEN_REDIRECT_NOTIFICATION': {
      return {
        ...state,
        displayRedirectNotification: true,
      }
    }
    case 'CLOSE_REDIRECT_NOTIFICATION': {
      return {
        ...state,
        displayRedirectNotification: false,
      }
    }
    case 'OPEN_SHOP_THE_LOOK': {
      return {
        ...state,
        displayShopTheLook: {
          open: true,
          products: action.payload,
        },
      }
    }
    case 'CLOSE_SHOP_THE_LOOK': {
      return {
        ...state,
        displayShopTheLook: {
          open: false,
          products: [],
        },
      }
    }
    case 'OPEN_FILTERS': {
      return {
        ...state,
        displayFilters: true,
      }
    }
    case 'CLOSE_FILTERS': {
      return {
        ...state,
        displayFilters: false,
      }
    }
    case 'TOGGLE_MOBILE_FILTERS': {
      return {
        ...state,
        displayMobileFilters: !state.displayMobileFilters,
      }
    }
    case 'TOGGLE_FILTERS': {
      return {
        ...state,
        displayFilters: !state.displayFilters,
      }
    }
    case 'TOGGLE_MOBILE_SEARCH_UI': {
      return {
        ...state,
        displaySearchUI: !state.displaySearchUI,
      }
    }
    case 'SHOW_MOBILE_SEARCH_UI': {
      return {
        ...state,
        displaySearchUI: true,
      }
    }
    case 'HIDE_MOBILE_SEARCH_UI': {
      return {
        ...state,
        displaySearchUI: false,
      }
    }
    case 'TOGGLE_FILTERS_ACTIVE': {
      return {
        ...state,
        filtersActive: action.payload,
      }
    }
    case 'SET_PCP_RESULTS_LOADED': {
      return {
        ...state,
        pcpResultsLoaded: action.payload,
      }
    }
    default: {
      return console.error('No action defined in UIContext.')
    }
  }
}

export const UIContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(uiReducer, initialState)

  const openCart = () => dispatch({ type: 'OPEN_CART' })
  const closeCart = () => dispatch({ type: 'CLOSE_CART' })
  const openMobileMenu = () => dispatch({ type: 'OPEN_MOBILE_MENU' })
  const closeMobileMenu = () => dispatch({ type: 'CLOSE_MOBILE_MENU' })
  const updateWishlistCount = (payload?: number) => dispatch({ type: 'UPDATE_WISHLIST_COUNT', payload })
  const setShowQuickAddToCart = (payload: string | boolean) => dispatch({ type: 'SET_QUICK_ADD_TO_CART', payload })
  const openRedirectNotification = () => dispatch({ type: 'OPEN_REDIRECT_NOTIFICATION' })
  const closeRedirectNotification = () => dispatch({ type: 'CLOSE_REDIRECT_NOTIFICATION' })
  const openShopTheLook = (payload: ProductSpotProduct[]) => dispatch({ type: 'OPEN_SHOP_THE_LOOK', payload })
  const closeShopTheLook = () => dispatch({ type: 'CLOSE_SHOP_THE_LOOK' })
  const openFilters = () => dispatch({ type: 'OPEN_FILTERS' })
  const closeFilters = () => dispatch({ type: 'CLOSE_FILTERS' })
  const toggleFilters = () => dispatch({ type: 'TOGGLE_FILTERS' })
  const toggleMobileFilters = () => dispatch({ type: 'TOGGLE_MOBILE_FILTERS' })
  const toggleMobileMenuSearchUI = () => dispatch({ type: 'TOGGLE_MOBILE_SEARCH_UI' })
  const showSearchUI = () => dispatch({ type: 'SHOW_MOBILE_SEARCH_UI' })
  const hideSearchUI = () => dispatch({ type: 'HIDE_MOBILE_SEARCH_UI' })
  const toggleFiltersActive = (payload: boolean) => dispatch({ type: 'TOGGLE_FILTERS_ACTIVE', payload })
  const setPcpResultsLoaded = (payload: boolean) => dispatch({ type: 'SET_PCP_RESULTS_LOADED', payload })

  const value = useMemo(
    () => ({
      ...state,
      openCart,
      closeCart,
      openMobileMenu,
      closeMobileMenu,
      updateWishlistCount,
      setShowQuickAddToCart,
      openRedirectNotification,
      closeRedirectNotification,
      openShopTheLook,
      closeShopTheLook,
      openFilters,
      closeFilters,
      toggleFilters,
      toggleMobileFilters,
      toggleMobileMenuSearchUI,
      showSearchUI,
      hideSearchUI,
      toggleFiltersActive,
      setPcpResultsLoaded,
    }),
    [state],
  )

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}

export const useUI = (): UIContextContextType => {
  const context = useContext(UIContext)

  if (context === undefined) {
    throw new Error('useUI must be used within a UIContextProvider.')
  }

  return context
}
