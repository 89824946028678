import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SECTION_BANNER_CTA_FRAGMENT, SectionBannerCtaSchema } from './sectionBannerCta.fragment'

export const SectionBannerCarouselSchema = z.object({
  _type: z.literal('sectionBannerCarousel'),
  _id: z.string(),
  name: z.string(),
  slides: z.array(SectionBannerCtaSchema),
  delay: z.number().optional(),
})

export type SectionBannerCarouselData = z.infer<typeof SectionBannerCarouselSchema>

export const SECTION_BANNER_CAROUSEL_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionBannerCarousel' => {
    _type,
    _id,
    name,
    slides[@->showInLocales[$locale] != false]-> {
      ${SECTION_BANNER_CTA_FRAGMENT(locale)}
    },
    defined(delay) => {
      delay
    }
  }
`)
