import { SanityReference } from '@sanity/client'
import { z } from 'zod'

import { SupportedLocale } from '../domain/constants'
import { Image } from './fragments/common/getImage.fragment'

export interface ImageWithOptionalProperties extends Omit<Image, 'asset' | 'isOpaque'> {
  // These are only optional because we don't send the reference to algolia as we don't currently use hotspot or crop for those images
  asset?: SanityReference
  isOpaque?: boolean
}

// Matches productType list in studio/schemas/documents/collection.ts
export const ProductTypeSchema = z.enum(['trousers', 'shorts', 'tops', 'shoes', 'other'])
export type ProductType = z.infer<typeof ProductTypeSchema>

// Matches collectionSeason list in studio/schemas/documents/collection.ts
export const CollectionSeasonSchema = z.enum(['ss', 'aw', 'as', 'na'])
export type CollectionSeason = z.infer<typeof CollectionSeasonSchema>

export const SlugSchema = z.object({
  _type: z.literal('slug').optional(),
  current: z.string(),
})

export type Slug = z.infer<typeof SlugSchema>

export const BadgesSchema = z
  .object({
    new: z.boolean().optional(),
    limitedEdition: z.boolean().optional(),
    widerStripes: z.boolean().optional(),
  })
  .nullable()

export type Badges = z.infer<typeof BadgesSchema>

export interface SanityMedia {
  asset: {
    originalFilename: string
    url: string
    width: number
    height: number
  }
}

export interface SanityBaseProductResponseForAlgolia {
  _id: string
  _type: string
  title: string
  slug: {
    current: string
  }
  images: SanityMedia[]
}

// Keep these typings up to date with the GROQ query please
// @see apps/data/src/repositories/sanity/fragments/product.fragment.ts

export type SanityStreamRequestPayload = {
  _id: string
  slug: {
    _type: 'localizedSlug'
  } & {
    [K in SupportedLocale]: {
      _type: string
      current: string
    }
  }
}
