import { CartWarning, ShopifyUserErrorWithoutCode } from '../../types'

export interface RemoveLineItemsDto {
  data: {
    cartLinesRemove: {
      cart: { id: string }
      warnings: CartWarning[]
      userErrors: ShopifyUserErrorWithoutCode[]
    }
  }
}

export const REMOVE_LINE_ITEMS_MUTATION = `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds){
    cart {
      id
    }
    warnings {
      code
      target
      message
    }
    userErrors {
      field
      message
    }
  }
}`
