import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceFilter } from '../../utils'
import { SectionType } from '../common/sectionTypes.fragment'
import { Section, SECTION_LIST, SectionSchema } from '../pages/sections.fragment'

const SectionLocalizerBaseSchema = z.object({
  _id: z.string(),
  _type: z.literal('sectionLocalizer'),
  fallback: z.boolean(),
})

export type SectionLocalizerData = z.infer<typeof SectionLocalizerBaseSchema> & {
  section: Section
}

export const SectionLocalizerSchema: z.ZodType<SectionLocalizerData> = SectionLocalizerBaseSchema.extend({
  section: z.lazy(() => SectionSchema),
})

export const SECTION_LOCALIZER_FRAGMENT = (locale = DEFAULT_LOCALE, types: SectionType[]): string => {
  const nestedTypes = types.map((type) => type?.localizedSections).filter(Boolean) || []

  if (nestedTypes.length === 0) {
    return ''
  }

  return defineQuery(`
    _type == 'sectionLocalizer' => {
      _id,
      _type,
      fallback,
      'section': select(
        fallback == true => ${coalesceFilter('section', locale)},
        section[$locale]
      )->{
        ${SECTION_LIST(locale, nestedTypes as SectionType[])}
      }
    }
  `)
}
