import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { GET_IMAGE_FRAGMENT, ImageSchema } from '../common/getImage.fragment'
import { PRODUCT_CARD_PRODUCT_FRAGMENT, ProductCardProductSchema } from '../components/productCardProduct.fragment'

export const SectionProductCardSchema = z.object({
  _id: z.string(),
  _type: z.literal('sectionProductCard'),
  product: ProductCardProductSchema,
  square: z.boolean(),
  hoverImage: z.optional(ImageSchema),
})

export type SectionProductCardData = z.infer<typeof SectionProductCardSchema>

export const SECTION_PRODUCT_CARD_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _id,
  _type,
  product-> {
    ${PRODUCT_CARD_PRODUCT_FRAGMENT(locale, 1)}
  },
  square,
  defined(hoverImage) => {
    hoverImage {
      ${GET_IMAGE_FRAGMENT()}
    }
  }
`)
