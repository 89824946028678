import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'

export const CookieTypeSchema = z.object({
  _type: z.literal('cookieType'),
  type: z.enum(['necessary', 'preferences', 'statistics', 'marketing']),
  title: z.string().optional(),
  description: z.string().optional(),
})

export type CookieType = z.infer<typeof CookieTypeSchema>

export const COOKIE_TYPE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
    _type,
    type,
    ${coalesceQuery('title', locale)},
    ${coalesceQuery('description', locale)},
`)
