import Cookies from 'js-cookie'

import { createCart, getCartById } from 'data-access'
import { ShopifyCart } from 'data-access/shopify/types'

import { I18N_COUNTRY_CODE, I18N_COUNTRY_CODE_RAW } from 'src/middleware'

const cartObjectMap = (cart: ShopifyCart) => {
  const lines = cart.lines.edges.map(({ node }) => node) || []

  return {
    ...cart,
    lines,
  }
}

const isExpired = (cart: ShopifyCart) => {
  const now = new Date()
  const createdAt = new Date(cart.createdAt)
  const differenceInDays = (now.getTime() - createdAt.getTime()) / (1000 * 3600 * 24)

  return differenceInDays > 85
}

export const getCart = async (locale: string | undefined, id?: string | null) => {
  const cart = await getCartById(locale, id)

  if (cart && !isExpired(cart)) {
    return cartObjectMap(cart)
  }

  const countryCode = Cookies.get(I18N_COUNTRY_CODE_RAW) || Cookies.get(I18N_COUNTRY_CODE)

  const { cart: newCart } = await createCart(
    locale,
    // Cloudflare uses 2 invalid ISO country codes we want to clean
    countryCode && ['XX', 'T1', 'en'].includes(countryCode) ? undefined : countryCode,
  )

  return cartObjectMap(newCart)
}
