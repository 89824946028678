import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { GET_IMAGE_FRAGMENT, ImageSchema } from '../common/getImage.fragment'

export const ColorSchema = z.object({
  _id: z.string(),
  _type: z.string(),
  hexCode: z.object({
    hex: z.string(),
  }),
  image: ImageSchema.optional(),
  colorName: z.string(),
  descriptiveName: z.string().optional(),
  colorQuote: z.string().optional(),
})

export type Color = z.infer<typeof ColorSchema>

export const COLOR_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _id,
  _type,
  hexCode {
    hex,
  },
  defined(img) => {
    "image": img {
      ${GET_IMAGE_FRAGMENT()}
    }
  },
  colorName,
  ${coalesceQuery('descriptiveName', locale)},
  defined(colorQuote) => {
    ${coalesceQuery('colorQuote', locale)},
  }
`)
