import { getCustomerWishList } from 'data-access'
import { getErrorMessage } from 'utilities/errors/errorMessage'

import { ShopifyProductInSanity } from '@/types/product'
import { WishlistLocaleKeys, WishlistObject } from '@/types/wishlist'

import { IronSessionCustomer } from './auth.util'
import { get, keys, set } from './localStorage.util'

const WISHLIST_LOCALE_KEYS: WishlistLocaleKeys = {
  nl: 'LOCAL_WISHLIST_NL',
  us: 'LOCAL_WISHLIST_US',
  it: 'LOCAL_WISHLIST_IT',
  gb: 'LOCAL_WISHLIST_UK',
  es: 'LOCAL_WISHLIST_ES',
  fr: 'LOCAL_WISHLIST_FR',
  de: 'LOCAL_WISHLIST_DE',
  en: 'LOCAL_WISHLIST_EN',
  dk: 'LOCAL_WISHLIST_DK',
  ch: 'LOCAL_WISHLIST_CH',
}

export const emptyWishlist = {
  lastUpdated: null,
  items: {},
} as WishlistObject

export const getWishlistLocaleKey = (storeLocale: string | undefined = 'en') => {
  if (process.env.NODE_ENV === 'development' && storeLocale === 'en') {
    return 'LOCAL_WISHLIST_STAGING'
  }

  return WISHLIST_LOCALE_KEYS?.[storeLocale] || WISHLIST_LOCALE_KEYS.en || 'WISHLIST'
}

export const getLocalWishlist = (locale: string | undefined) => {
  const wishlistKey = keys?.[getWishlistLocaleKey(locale)]
  const unparsedLocalWishlist = wishlistKey ? get(wishlistKey) : undefined

  // If there is no local wishlist, return an empty wishlist
  return unparsedLocalWishlist ? (JSON.parse(unparsedLocalWishlist) as WishlistObject) : (emptyWishlist as WishlistObject)
}

export const setLocalWishlist = (wishlist: WishlistObject, locale: string | undefined) => {
  const wishlistKey = keys?.[getWishlistLocaleKey(locale)]

  if (wishlistKey) {
    set(wishlistKey, JSON.stringify(wishlist))
  }
}

const updateRemoteWishlist = async ({ locale, customer, wishlist }: { locale: string | undefined; customer: IronSessionCustomer; wishlist: WishlistObject }) => {
  if (customer.isLoggedIn !== false) {
    try {
      await fetch('/api/customer/customer-wishlist', {
        method: 'POST',
        body: JSON.stringify({ locale, customerId: customer.id, wishlist }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
    } catch (error) {
      console.error(`An error occurred: ${getErrorMessage(error)}`)
    }
  }
}

const combineWishlists = (wishlist1: WishlistObject, wishlist2: WishlistObject) => {
  // Get all unique productIds (object keys) to iterate with
  const allProductIds = Object.keys({ ...wishlist1?.items, ...wishlist2.items }).map(Number)

  const latestUpdatedAt =
    wishlist1.lastUpdated !== null && wishlist2.lastUpdated !== null && wishlist1.lastUpdated > wishlist2.lastUpdated ? wishlist1.lastUpdated : wishlist2.lastUpdated

  const combinedWishlist = {
    lastUpdated: latestUpdatedAt,
    items: {},
  }

  // Save whether the wishlists are different and therefore we need to update both to keep them in sync
  let areDifferent = false

  allProductIds.forEach((productId) => {
    // If the item doesn't exist on both wishlists, it only exists in 1 wishlist, return that wishlist's data
    if (!(wishlist1?.items[productId] && wishlist2.items[productId])) {
      areDifferent = true
      const wishlistWithExistingData = wishlist1?.items[productId] ? wishlist1 : wishlist2

      return (combinedWishlist.items = {
        ...combinedWishlist.items,
        [productId]: { ...wishlistWithExistingData.items[productId] },
      })
    }

    // If it does exist in both but their time stamps aren't the same
    // find the one with the latest timestamp, use that wishlist's data
    if (wishlist1?.items?.[productId]?.updatedAt !== wishlist2?.items?.[productId]?.updatedAt) {
      areDifferent = true
      const wishlistWithLatestData = (wishlist1?.items?.[productId]?.updatedAt ?? 0) > (wishlist2?.items?.[productId]?.updatedAt ?? 0) ? wishlist1 : wishlist2

      return (combinedWishlist.items = {
        ...combinedWishlist.items,
        [productId]: { ...wishlistWithLatestData.items[productId] },
      })
    }

    // If their timestamps are the same, return any
    return (combinedWishlist.items = {
      ...combinedWishlist.items,
      [productId]: { ...wishlist1.items[productId] },
    })
  })

  return { combinedWishlist, areDifferent }
}

export const synchronizeWishlist = async ({ customer, locale }: { customer: IronSessionCustomer; locale: string | undefined }) => {
  const localWishlist = getLocalWishlist(locale)

  // If customer isn't logged in and already has a local wishlist, do nothing
  if (!customer?.isLoggedIn && localWishlist.lastUpdated) {
    return localWishlist
  }

  // If customer isn't logged in and has no localWishlist yet, set it to emptyWishlist state
  if (!customer?.isLoggedIn && !localWishlist.lastUpdated) {
    setLocalWishlist(emptyWishlist, locale)

    return emptyWishlist
  }

  if (customer?.isLoggedIn) {
    const value = await getCustomerWishList(locale, customer.customerAccessToken.accessToken)
    const remoteWishlist = value ? JSON.parse(value) : null

    // If customer has a remote wishlist but has no local wishlist set the local wishlist to the remote wishlist
    if (!localWishlist?.lastUpdated && remoteWishlist?.lastUpdated) {
      setLocalWishlist(remoteWishlist, locale)

      return remoteWishlist
    }

    // If there's no remote wishlist and no local wishlist, set local to emptyWishlist
    if (!localWishlist?.lastUpdated && !remoteWishlist?.lastUpdated) {
      setLocalWishlist(emptyWishlist, locale)

      return emptyWishlist
    }

    // If there's no remote wishlist, but a local one, use local and updateRemote
    if (localWishlist?.lastUpdated && !remoteWishlist?.lastUpdated) {
      await updateRemoteWishlist({ locale, wishlist: localWishlist, customer })

      return localWishlist
    }

    // If there's a remote wishlist and a local one, combine them and updateRemote
    if (localWishlist?.lastUpdated && remoteWishlist?.lastUpdated) {
      // Combine both wishlists
      const { combinedWishlist, areDifferent } = combineWishlists(localWishlist, remoteWishlist)

      if (areDifferent) {
        // Store locally
        setLocalWishlist(combinedWishlist, locale)

        // Await POST local one to customer metafield
        await updateRemoteWishlist({ locale, wishlist: combinedWishlist, customer })

        return combinedWishlist
      }

      return localWishlist
    }
  }

  // If all else fails return an emptyWishlist
  return emptyWishlist
}

export const updateWishlist = async ({
  product,
  customer,
  locale,
  action,
}: {
  product: ShopifyProductInSanity
  customer: IronSessionCustomer
  locale: string | undefined
  action: 'add' | 'remove'
}) => {
  const { items } = getLocalWishlist(locale)
  const id = product.shopifyProductId ? parseInt(product.shopifyProductId.split('/').slice(-1)[0] || '') : NaN
  const currentTimestamp = Date.now()

  // @ts-ignore
  items[id] = {
    ...(items[id] ? { ...items[id] } : { product }),
    inWishlist: action === 'remove' ? false : true,
    updatedAt: currentTimestamp,
  }

  const wishlist = { items, lastUpdated: currentTimestamp }

  setLocalWishlist(wishlist, locale)

  if (customer.isLoggedIn) {
    await updateRemoteWishlist({ locale, wishlist, customer })
  }
}
