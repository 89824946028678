import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceFilter } from '../../utils'

export const SectionTypeSchema = z.object({
  _id: z.string(),
  _type: z.string(),
  localizedSections: z
    .object({
      _id: z.string(),
      _type: z.string(),
    })
    .optional(),
  sections: z
    .array(
      z.object({
        _id: z.string(),
        _type: z.string(),
      }),
    )
    .optional(),
  leftSections: z
    .array(
      z.object({
        _id: z.string(),
        _type: z.string(),
      }),
    )
    .optional(),
  rightSections: z
    .array(
      z.object({
        _id: z.string(),
        _type: z.string(),
      }),
    )
    .optional(),
  controlSection: z
    .object({
      _id: z.string(),
      _type: z.string(),
    })
    .optional(),
  alternativeSection: z
    .object({
      _id: z.string(),
      _type: z.string(),
    })
    .optional(),
})

export type SectionType = z.infer<typeof SectionTypeSchema>

export const SECTION_TYPES_FRAGMENT = (locale = DEFAULT_LOCALE, count = 1): string => {
  if (count > 3) {
    return defineQuery(`
    _id,
    _type
  `)
  }
  return defineQuery(`
    _id,
    _type,
    _type == "sectionLocalizer" => {
      fallback,
      "localizedSections": select(
        fallback == true => ${coalesceFilter('section', locale)},
        section[$locale]
      )->{
        ${SECTION_TYPES_FRAGMENT(locale, count + 1)},
      }
    },
    _type == "sectionLayout" => {
      sections[] {
        ...section-> {
          ${SECTION_TYPES_FRAGMENT(locale, count + 1)},
        }
      }
    },
    _type == "sectionHalfAndHalf" => {
      leftSections[]->{
        _id,
        _type
      },
      rightSections[]->{
        _id,
        _type
      }
    }
`)
}
