import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'

export const DeliveryTimeSchema = z.object({
  type: z.enum(['orderedBy', 'businessDays']),
  orderedBy: z.string().optional(),
  businessDays: z.string().optional(),
})

export const ShippingCostsSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('free'),
  }),
  z.object({
    type: z.literal('freeFrom'),
    freeFromPrice: z.number(),
    otherwisePrice: z.number(),
  }),
  z.object({
    type: z.literal('fixed'),
    price: z.number(),
  }),
  z.object({
    type: z.literal('custom'),
    description: z.string(),
  }),
])

export const ShipmentAreaSchema = z.object({
  countries: z.array(
    z.object({
      name: z.string(),
      countryCode: z.string(),
    }),
  ),
  deliveryTime: DeliveryTimeSchema,
  shippingCosts: ShippingCostsSchema,
  returnsAreFree: z.boolean(),
})

export const SectionShippingInformationSchema = z.object({
  _type: z.literal('sectionShippingInformation'),
  _id: z.string(),
  showSearchbar: z.boolean(),
  extraInformationHeader: z.string().optional(),
  extraInformation: RichTextSchema.optional(),
  shipmentAreas: z.array(ShipmentAreaSchema).optional(),
})

export type DeliveryTime = z.infer<typeof DeliveryTimeSchema>
export type ShippingCosts = z.infer<typeof ShippingCostsSchema>
export type ShipmentArea = z.infer<typeof ShipmentAreaSchema>
export type SectionShippingInformationData = z.infer<typeof SectionShippingInformationSchema>

export const SECTION_SHIPPING_INFORMATION_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionShippingInformation' => {
    _type,
    _id,
    showSearchbar,
    defined(extraInformationHeader) => {
      ${coalesceQuery('extraInformationHeader', locale)}
    },
    defined(extraInformation) => {
      ${RICH_TEXT_FRAGMENT(locale, 'extraInformation')}
    },
    defined(shipmentAreas) => {
      shipmentAreas[]-> {
        countries[]-> {
          ${coalesceQuery('name', locale)},
          countryCode,
        },
        deliveryTime {
          type,
          defined(orderedBy) => {
            orderedBy
          },
          defined(businessDays) => {
            businessDays
          }
        },
        'shippingCosts': shipping-> {
          ${coalesceQuery('description', locale)},
          ...costs {
            type,
            defined(freeFromPrice) => {
              ${coalesceQuery('freeFromPrice', locale)}
            },
            defined(otherwisePrice) => {
              ${coalesceQuery('otherwisePrice', locale)}
            },
            defined(price) => {
              ${coalesceQuery('price', locale)}
            },
          }
        },
        'returnsAreFree': returns->.costs.type == 'free',
      }
    }
  }
`)
