import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { ProductTypeSchema } from '../../types'
import { coalesceQuery } from '../../utils'
import { GET_IMAGE_FRAGMENT } from '../common/getImage.fragment'
import { CareInstructionSchema } from '../components/careInstructions.fragment'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'

const CareInstructionWithDescriptionSchema = z.object({
  description: RichTextSchema,
  careInstructions: z.array(CareInstructionSchema),
})

export const CareInstructionSetWithDescriptionSchema = z.object({
  washing: CareInstructionWithDescriptionSchema,
  drying: CareInstructionWithDescriptionSchema,
  ironing: CareInstructionWithDescriptionSchema,
  storage: CareInstructionWithDescriptionSchema,
  wearAndTear: CareInstructionWithDescriptionSchema,
})

export const StyleSchema = z.object({
  _id: z.string(),
  title: z.string(),
  productType: ProductTypeSchema,
  products: z.array(
    z.object({
      _id: z.string(),
      title: z.string(),
      care: CareInstructionSetWithDescriptionSchema,
    }),
  ),
  care: CareInstructionSetWithDescriptionSchema,
})

export const SectionCareSchema = z.object({
  _id: z.string(),
  _type: z.literal('sectionCare'),
  carePageData: z.object({
    styles: z.array(StyleSchema),
  }),
})

export type CareInstructionSetWithDescription = z.infer<typeof CareInstructionSetWithDescriptionSchema>
export type Style = z.infer<typeof StyleSchema>
export type SectionCareData = z.infer<typeof SectionCareSchema>

export const SECTION_CARE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionCare' => {
    _id,
    _type,
    'carePageData': {
      "styles": *[_type == "collection" && isPrimary == true && showInLocales[$locale] != false && (defined(care) || count(products[@->.care != null]._id) != 0)] {
        _id,
        ${coalesceQuery('title', locale)},
        productType,
        products[@->.care != null]-> {
          _id,
          ${coalesceQuery('productTitle', locale, 'title')},
          ${CARE_INSTRUCTIONS_SET_WITH_DESCRIPTION_FRAGMENT(locale)}
        },
        ${CARE_INSTRUCTIONS_SET_WITH_DESCRIPTION_FRAGMENT(locale)}
      }
    }
  }
`)

const CARE_INSTRUCTIONS_SET_WITH_DESCRIPTION_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  care-> {
    washing {
      ${CARE_INSTRUCTIONS_WITH_DESCRIPTION_FRAGMENT(locale)}
    },
    drying {
      ${CARE_INSTRUCTIONS_WITH_DESCRIPTION_FRAGMENT(locale)}
    },
    ironing {
      ${CARE_INSTRUCTIONS_WITH_DESCRIPTION_FRAGMENT(locale)}
    },
    storage {
      ${CARE_INSTRUCTIONS_WITH_DESCRIPTION_FRAGMENT(locale)}
    },
    wearAndTear {
      ${CARE_INSTRUCTIONS_WITH_DESCRIPTION_FRAGMENT(locale)}
    },
  }
`)

const CARE_INSTRUCTIONS_WITH_DESCRIPTION_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  defined(description) => {
    ${RICH_TEXT_FRAGMENT(locale, 'description')}
  },
  careInstructions[]-> {
    ${coalesceQuery('instruction', locale)},
    type,
    icon {
      ${GET_IMAGE_FRAGMENT()}
    }
  }
`)
