import { defineQuery } from 'next-sanity'
import { z } from 'zod'

export const SectionPersonalInfoSchema = z.object({
  _type: z.literal('sectionPersonalInfo'),
  _id: z.string(),
})

export type SectionPersonalInfoData = z.infer<typeof SectionPersonalInfoSchema>

export const SECTION_PERSONAL_INFO_FRAGMENT = () =>
  defineQuery(`
  _type == 'sectionPersonalInfo' => {
    _type,
    _id,
  }
`)
