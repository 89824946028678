import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { SupportedLocale } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'

export const CustomerServiceSchema = z.object({
  openingHoursHeader: z.string().optional(),
  openingHours: z.string().optional(),
  specialOpeningHours: z.string().optional(),
  email: z.string().optional(),
  phone: z.string().optional(),
  whatsApp: z.string().optional(),
  messenger: z.string().optional(),
})

export type CustomerService = z.infer<typeof CustomerServiceSchema>

export const CUSTOMER_SERVICE_FRAGMENT = (locale: SupportedLocale) =>
  defineQuery(`
  "customerServiceData": *[_type == "customerService"][0] {
    ${coalesceQuery('openingHoursHeader', locale)},
    ${coalesceQuery('openingHours', locale)},
    defined(specialOpeningHours) => {
      "specialOpeningHours": specialOpeningHours[$locale],
    },
    defined(email) => {
      "email": email[$locale],
    },
    defined(phone) => {
      "phone": phone[$locale],
    },
    defined(whatsApp) => {
      "whatsApp": whatsApp[$locale],
    },
    defined(messenger) => {
      "messenger": messenger[$locale],
    }
  }
`)
