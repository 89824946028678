import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import posthog from 'posthog-js'

import { addLineItems, applyDiscountCode, removeLineItems, replaceLineItems, updateCartAttributes } from 'data-access'
import { ShopifyUserErrorWithoutCode } from 'data-access/shopify/types'
import { equals } from 'utilities/array/compareStringArrays'
import { getErrorMessage } from 'utilities/errors/errorMessage'

import { getCart } from 'src/domain/cart.domain'
import { getCartLocaleKey } from '../../utils/cart.util'
import { get, keys, set } from '../../utils/localStorage.util'

type CartAttribute = { key: string; value: string }
type TrackingData = { queryID?: string; handle?: string }
type LineItem = { merchandiseId: string; quantity: number }

const logError = (context: string, error: unknown) => {
  posthog.captureException(new Error(getErrorMessage(error), { cause: context }))
  console.error(`[Cart Error - ${context}]:`, error)
}

const userErrorsToString = (userErrors: ShopifyUserErrorWithoutCode[]) => userErrors.flatMap(({ field, message }) => `${field}: ${message}`).join(', ')

const determineAttributeUpdate = (newValue: string, existingAttributes: CartAttribute[], key: string): { attributes: CartAttribute[]; needsUpdate: boolean } => {
  const attributeIndex = existingAttributes.findIndex((attr) => attr.key === key)

  if (attributeIndex !== -1) {
    // If value is the same, no update needed
    if (existingAttributes?.[attributeIndex]?.value === newValue) {
      return { attributes: existingAttributes, needsUpdate: false }
    }

    // Create new attributes array with updated value
    const updatedAttributes = [...existingAttributes.slice(0, attributeIndex), { key, value: newValue }, ...existingAttributes.slice(attributeIndex + 1)]

    return { attributes: updatedAttributes, needsUpdate: true }
  }

  // Append new attribute if not existing
  return {
    attributes: [...existingAttributes, { key, value: newValue }],
    needsUpdate: true,
  }
}

const manageLineItemOrdering = (existingAttributes: CartAttribute[], lines: LineItem[], reorderCart: boolean) => {
  if (!reorderCart) return { attributes: existingAttributes, needsUpdate: false }

  const newLineItems = lines.map((line) => line.merchandiseId)
  const previousLineItems = existingAttributes.find((attr) => attr.key === 'sortedLineItems')?.value.split(',') ?? []

  const combinedLineItems = [...new Set([...newLineItems, ...previousLineItems])]
  const newValue = combinedLineItems.toString()

  return determineAttributeUpdate(newValue, existingAttributes, 'sortedLineItems')
}

const manageAlgoliaTracking = (existingAttributes: CartAttribute[], trackingData?: TrackingData) => {
  if (!trackingData?.queryID || !trackingData?.handle) {
    return { attributes: existingAttributes, needsUpdate: false }
  }

  const newAlgoliaEntry = `${trackingData.handle}:${trackingData.queryID}`
  const previousAlgoliaData =
    existingAttributes
      .find((attr) => attr.key === '_algoliaData')
      ?.value.split(',')
      .filter((entry) => entry && entry.includes(':')) ?? []

  const combinedAlgoliaData = [...new Set([...previousAlgoliaData, newAlgoliaEntry])]
  const newValue = combinedAlgoliaData.toString()

  return determineAttributeUpdate(newValue, existingAttributes, '_algoliaData')
}

const useCart = () => {
  const queryClient = useQueryClient()
  const { locale } = useRouter()
  const cartLocaleKey = getCartLocaleKey(locale)

  const localStorageKey = keys[cartLocaleKey]
  if (!localStorageKey) {
    throw new Error('Unknown local storage key for cart.')
  }

  const {
    data: cart,
    error,
    isLoading,
    refetch: refreshCart,
  } = useQuery({
    queryKey: locale ? ['cart', locale] : ['cart'],
    queryFn: async () => {
      const fetchedCart = await getCart(locale, get(localStorageKey))

      if (!fetchedCart) {
        throw new Error('No cart data retrieved')
      }

      set(localStorageKey, fetchedCart.id)
      return fetchedCart
    },
    enabled: !!locale,
    staleTime: 1000 * 60, // 1 minute
    refetchOnWindowFocus: true,
  })

  if (error) {
    console.error('Error fetching cart:', error)
  }

  const cartId = cart?.id
  const lineItems = cart?.lines || []
  const totalItems = lineItems.reduce((sum, item) => sum + item.quantity, 0)

  const computedCartValues = {
    isEmpty: totalItems === 0,
    totalItems,
    currencyCode: cart?.cost?.subtotalAmount?.currencyCode || (locale === 'gb' ? 'GBP' : 'EUR'),
  }

  const lineItemVariantIdsInCart = (cart?.lines || []).map(({ merchandise }) => merchandise.id)
  const lineItemOrderingInfo =
    cart?.attributes
      ?.filter((attribute) => attribute?.key === 'sortedLineItems')?.[0]
      ?.value?.split(',')
      ?.filter((gid) => lineItemVariantIdsInCart.includes(gid)) || []

  const sortOrderInfoComplete =
    lineItemOrderingInfo.length > 0 && (cart?.lines || []).length === lineItemOrderingInfo.length && equals(lineItemOrderingInfo, lineItemVariantIdsInCart)

  // Cart Operations
  const cartOperations = {
    async setCartAttributes(customAttributes: CartAttribute[]) {
      if (!cartId) {
        logError('Set Cart Attributes', 'Cart ID not found')
        return { success: false, error: 'Cart ID not found' }
      }

      // Remove empty values
      const cleanedCustomerAttributes = customAttributes.filter((attr) => attr.value !== '')

      const { cart, userErrors, warnings } = await updateCartAttributes(locale, cartId, cleanedCustomerAttributes)

      if (warnings?.length) {
        logError('Set Cart Attributes', warnings)
      }

      if (userErrors?.length) {
        logError('Set Cart Attributes', userErrors)
        return { success: false, error: userErrorsToString(userErrors) }
      }

      if (process.env.NODE_ENV === 'development') {
        console.debug('Set checkout attributes', { cleanedCustomerAttributes, cart })
      }

      return { success: true, error: undefined }
    },

    async addItems({ lines, reorderCart = true, trackingData }: { lines: LineItem[]; reorderCart?: boolean; trackingData?: TrackingData }) {
      if (!cartId || !lines.some((line) => line.quantity > 0)) {
        logError('Add Items', 'Cart ID not found or no items to add')
        return { success: false, error: 'Cart ID not found or no items to add' }
      }

      const { attributes: newAttributes, needsUpdate: sortingNeedsUpdate } = manageLineItemOrdering(cart?.attributes || [], lines, reorderCart)

      const { attributes, needsUpdate: algoliaNeedsUpdate } = manageAlgoliaTracking(newAttributes, trackingData)

      // Update attributes if needed
      if (sortingNeedsUpdate || algoliaNeedsUpdate) {
        const { error } = await cartOperations.setCartAttributes(attributes)

        if (error) {
          return { success: false, error }
        }
      }

      const { userErrors, warnings } = await addLineItems(locale, cart.id, lines)

      if (userErrors?.length || warnings?.length) {
        logError('Add Line Items', userErrors || warnings)
        return { success: false, error: userErrorsToString(userErrors) }
      }

      // Optimistically update the query cache
      queryClient.invalidateQueries({ queryKey: ['cart', locale] })

      return { success: true, error: undefined }
    },

    async removeItems({ lineIds, productHandle }: { lineIds: string[]; productHandle: string }) {
      if (!cartId) {
        logError('Remove Items', 'Cart ID not found')
        return { success: false, error: true }
      }

      const existingAttributes = cart?.attributes || []
      const newAttributes = [...existingAttributes]

      const algoliaDataIndex = newAttributes.findIndex((attr) => attr.key === '_algoliaData')
      if (algoliaDataIndex !== -1) {
        const algoliaEntries = newAttributes[algoliaDataIndex]?.value
          ?.split(',')
          ?.filter((entry) => entry && entry.includes(':'))
          ?.filter((entry) => !entry.startsWith(`${productHandle}:`))

        if (algoliaEntries && algoliaEntries.length > 0) {
          newAttributes[algoliaDataIndex] = {
            key: '_algoliaData',
            value: algoliaEntries.toString(),
          }
        } else {
          // Remove attribute if no entries left
          newAttributes.splice(algoliaDataIndex, 1)
        }

        const { error } = await cartOperations.setCartAttributes(newAttributes)
        if (error) {
          return { success: false, error: true }
        }
      }

      const { userErrors, warnings } = await removeLineItems(locale, cart.id, lineIds)
      if (userErrors?.length || warnings?.length) {
        logError('Remove Line Items', userErrors || warnings)
        return { success: false, error: true }
      }

      refreshCart()
      return { success: true, error: undefined }
    },

    async replaceItem(item: { id: string; quantity: number }) {
      if (!cartId) {
        logError('Replace Item', 'Cart ID not found')
        return { success: false, error: true }
      }

      const lineItemsToReplace = cart?.lines.reduce(
        (lines, { id, quantity, attributes }) => (id === item.id ? lines : [...lines, { id, quantity, attributes }]),
        item.quantity === 0 ? [] : [item],
      )

      const { userErrors, warnings } = await replaceLineItems(locale, cartId, lineItemsToReplace)

      if (userErrors?.length || warnings?.length) {
        logError('Replace Item', userErrors || warnings)
        return { success: false, error: true }
      }

      refreshCart()
      return { success: true, error: undefined }
    },

    async addDiscountCode(discountCodes: string[]) {
      if (!cartId) {
        logError('Add Discount Code', 'Cart ID not found')
        return { success: false, error: true }
      }

      const {
        userErrors,
        cart: { discountCodes: discountCodesOnCart },
      } = await applyDiscountCode(locale, cartId, discountCodes)

      if (userErrors?.length) {
        logError('Add Discount Code', userErrors)
        return { success: false, error: true }
      }

      const discountCodeApplied = discountCodesOnCart.find((discountCode) => discountCode.code === discountCodes[0])?.applicable

      if (!discountCodeApplied) {
        return { success: false, error: true }
      }

      refreshCart()
      return { success: true, error: undefined }
    },
  }

  return {
    cartId,
    error,
    isLoading: isLoading || cart === undefined,
    cart: {
      ...cart,
      webUrl: `${cart?.checkoutUrl}&shop_pay_checkout_as_guest=true&skip_shop_pay=true`,
    },
    lineItems:
      (sortOrderInfoComplete ? (lineItemOrderingInfo ?? []).map((item) => (cart?.lines ?? []).find(({ merchandise }) => item === merchandise?.id)) : cart?.lines || []).filter(
        Boolean,
      ) || [],
    ...cartOperations,
    ...computedCartValues,
  }
}

export default useCart
