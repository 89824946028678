import { defineQuery } from 'next-sanity'
import { z } from 'zod'

const PaddingSchema = z.enum([
  '0px',
  '2px',
  '4px',
  '6px',
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '32px',
  '36px',
  '40px',
  '44px',
  '48px',
  '56px',
  '64px',
  '80px',
  '96px',
  '112px',
  '128px',
  '144px',
  '160px',
  '176px',
  '192px',
  '208px',
  '224px',
  '240px',
  '256px',
  '288px',
  '320px',
  '384px',
])

export const SectionPaddingSchema = z.object({
  _type: z.literal('sectionPadding'),
  _id: z.string(),
  mobilePadding: PaddingSchema,
  desktopPadding: PaddingSchema,
})

export type SectionPaddingData = z.infer<typeof SectionPaddingSchema>

export const SECTION_PADDING_FRAGMENT = () =>
  defineQuery(`
  _type == 'sectionPadding' => {
    _id,
    _type,
    mobilePadding,
    desktopPadding
  }
`)
