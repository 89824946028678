import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { IMAGE_TEXT_LIST_ITEM_FRAGMENT, ImageTextListItemSchema } from '../components/imageTextListItem.fragment'

export const SectionImageTextListSchema = z.object({
  _type: z.literal('sectionImageTextList'),
  _id: z.string(),
  name: z.string(),
  title: z.string().optional(),
  backgroundColor: z
    .object({
      hex: z.string(),
    })
    .optional(),
  backgroundColorExtended: z.boolean().optional(),
  direction: z.enum(['row', 'column']),
  imageTextListItems: z.array(ImageTextListItemSchema).optional(),
})

export type SectionImageTextListData = z.infer<typeof SectionImageTextListSchema>

export const SECTION_IMAGE_TEXT_LIST_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionImageTextList' => {
    _type,
    _id,
    name,
    defined(title) => {
      ${coalesceQuery('title', locale)},
    },
    backgroundColor {
      hex
    },
    backgroundColorExtended,
    direction,
    defined(imageTextListItems) => {
      imageTextListItems[]-> {
        ${IMAGE_TEXT_LIST_ITEM_FRAGMENT(locale)}
      },
    },
  }
`)
