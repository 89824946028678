import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'

export const SectionMediaSchema = z.object({
  _type: z.literal('sectionMedia'),
  _id: z.string(),
  media: MediaSchema.optional(),
  desktopView: z.enum(['single', 'singleMediaArticle']),
  padding: z.boolean(),
})

export type SectionMediaData = z.infer<typeof SectionMediaSchema>

export const SECTION_MEDIA_FRAGMENT = () =>
  defineQuery(`
  _type == 'sectionMedia' => {
    _type,
    _id,
    defined(media) => {
      media-> {
        ${MEDIA_FRAGMENT()}
      },
    },
    desktopView,
    padding,
  }
`)
