import { defineQuery } from 'next-sanity'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { Slug } from '../../types'
import { Color, COLOR_FRAGMENT } from './color.fragment'

export interface ProductColor {
  _id: string
  slug: Slug
  title: string
  productColor?: Color
}

export const PRODUCT_COLOR_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _id,
  slug,
  title,
  "productColor": color-> {
    ${COLOR_FRAGMENT(locale)}
  },
`)
