import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { GET_IMAGE_FRAGMENT, ImageSchema } from '../common/getImage.fragment'

const MediaRemoteVideoSchema = z.object({
  _type: z.literal('media'),
  _id: z.string(),
  type: z.literal('remoteVideo'),
  remoteVideoUrl: z.string(),
  remoteVideoUrlDesktop: z.string().optional(),
  videoAutoplay: z.boolean(),
  videoControls: z.boolean(),
  videoPoster: ImageSchema.optional(),
  videoPosterDesktop: ImageSchema.optional(),
})

const MediaImageSchema = z.object({
  _type: z.literal('media'),
  _id: z.string(),
  type: z.literal('image'),
  alt: z.string(),
  desktop: ImageSchema.optional(),
  mobile: ImageSchema.optional(),
})

export const MediaSchema = z.discriminatedUnion('type', [MediaRemoteVideoSchema, MediaImageSchema])

export type Media = z.infer<typeof MediaSchema>
export type MediaVideo = z.infer<typeof MediaRemoteVideoSchema>
export type MediaImage = z.infer<typeof MediaImageSchema>

export const MEDIA_FRAGMENT = () =>
  defineQuery(`
  _type,
  _id,
  type,
  defined(alt) => {
    alt
  },
  defined(desktop) => {
    desktop {
      ${GET_IMAGE_FRAGMENT()}
    }
  },
  defined(mobile) => {
    mobile {
      ${GET_IMAGE_FRAGMENT()}
    }
  },
  defined(videoPoster) => {
    videoPoster {
      ${GET_IMAGE_FRAGMENT()}
    }
  },
  defined(videoPosterDesktop) => {
    videoPosterDesktop {
      ${GET_IMAGE_FRAGMENT()}
    }
  },
  type == "remoteVideo" && defined(remoteVideoUrl) => {
    remoteVideoUrl
  },
  type == "remoteVideo" && defined(remoteVideoUrlDesktop) => {
    remoteVideoUrlDesktop
  },
  'videoAutoplay': videoAutoplay != false,
  'videoControls': videoControls != false,
`)
