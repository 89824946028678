import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { GET_IMAGE_FRAGMENT, ImageSchema } from '../common/getImage.fragment'

export const UpsellSchema = z.object({
  upsellCollection: z
    .object({
      _type: z.string(),
      slug: z.object({
        current: z.string().optional(),
        _type: z.string().optional(),
      }),
    })
    .optional(),
  upsellCollectionImage: ImageSchema,
  toInclude: z.array(z.string()).optional(),
  toExclude: z.array(z.string()).optional(),
  upsellLinkText: z.string(),
  upsellCollectionMessage: z.string(),
})

export type Upsell = z.infer<typeof UpsellSchema>

export const UPSELL_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  defined(upsellCollection) => {
    ${coalesceQuery(
      'upsellCollection',
      locale,
      'upsellCollection',
      `-> {
        _type,
        defined(slug) => {
          slug,
          ${coalesceQuery('slug', locale, 'localizedSlug')}
        } {
          'slug': coalesce(localizedSlug, slug)
        }
      }`,
    )}
  },
  ${coalesceQuery('upsellCollectionImage', locale, '', `{${GET_IMAGE_FRAGMENT()}}`)},
  defined(toInclude) => {
    "toInclude": array::unique(
      toInclude[]->{
        "slug": coalesce(
          products[]->slug.current,
          [slug.current]
        )
      }.slug[]
    )
  },
  defined(toExclude) => {
    "toExclude": array::unique(
      toExclude[]->{
        "slug": coalesce(
          products[]->slug.current,
          [slug.current]
        )
      }.slug[]
    )
  },
  ${coalesceQuery('upsellLinkText', locale)},
  ${coalesceQuery('upsellCollectionMessage', locale)}
`)
