import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { LINK_FRAGMENT, LinkSchema } from '../components/link.fragment'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'
import { CUSTOMER_SERVICE_FRAGMENT, CustomerServiceSchema } from '../globals/customerService.fragment'

export const SupportPageSchema = z.object({
  title: z.string(),
  link: LinkSchema,
  icon: z.string(),
})

export type SupportPage = z.infer<typeof SupportPageSchema>

export const SectionSupportOverviewSchema = z.object({
  _type: z.literal('sectionSupportOverview'),
  _id: z.string(),
  title: z.string(),
  description: RichTextSchema.optional(),
  pages: z.array(SupportPageSchema).optional(),
  customerServiceData: CustomerServiceSchema,
})

export type SectionSupportOverviewData = z.infer<typeof SectionSupportOverviewSchema>

export const SECTION_SUPPORT_OVERVIEW_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionSupportOverview' => {
    _type,
    _id,
    ${coalesceQuery('title', locale)},
    defined(description) => {
      ${RICH_TEXT_FRAGMENT(locale, 'description')}
    },
    pages[@->.showInLocales[$locale] != false]-> {
      ${coalesceQuery('title', locale)},
      link-> {
        ${LINK_FRAGMENT(locale)}
      },
      icon,
    },
    ${CUSTOMER_SERVICE_FRAGMENT(locale)}
  }
`)
