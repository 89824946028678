import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { CTA_FRAGMENT, CtaSchema } from '../components/cta.fragment'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'

export const HighlightDataSchema = z.object({
  _id: z.string(),
  _type: z.string(),
  title: z.string(),
  text: z.string(),
  image: MediaSchema,
  button: CtaSchema.optional(),
})

export const SectionHighlightsSchema = z.object({
  _type: z.literal('sectionHighlights'),
  _id: z.string(),
  title: z.string(),
  highlights: z.array(HighlightDataSchema),
})

export type HighlightData = z.infer<typeof HighlightDataSchema>
export type SectionHighlightsData = z.infer<typeof SectionHighlightsSchema>

export const SECTION_HIGHLIGHTS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionHighlights' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    highlights[]-> {
      _id,
      _type,
      ${coalesceQuery('title', locale)},
      ${coalesceQuery('text', locale)},
      "image": image-> {
        ${MEDIA_FRAGMENT()}
      },
      defined(button) => {
        button-> {
          ${CTA_FRAGMENT(locale)}
        }
      },
    },
  }
`)
