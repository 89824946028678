import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'
import { CUSTOMER_SERVICE_FRAGMENT, CustomerServiceSchema } from '../globals/customerService.fragment'

export const ContactBlockSchema = z.object({
  _type: z.literal('reference'),
  _id: z.string(),
  content: RichTextSchema,
  contactType: z.enum(['phone', 'email', 'messenger', 'chat', 'whatsapp']).optional(),
  showLink: z.boolean().optional(),
})

export const SectionContactSchema = z.object({
  _type: z.literal('sectionContact'),
  _id: z.string(),
  formHeader: z.string(),
  listId: z.string(),
  contactBlocks: z.array(ContactBlockSchema),
  customerServiceData: CustomerServiceSchema,
})

export type ContactBlock = z.infer<typeof ContactBlockSchema>
export type SectionContactData = z.infer<typeof SectionContactSchema>

export const SECTION_CONTACT_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionContact' => {
    _type,
    _id,
    ${coalesceQuery('formHeader', locale)},
    ${coalesceQuery('listId', locale)},
    contactBlocks[@->.showInLocales[$locale] != false]-> {
      _type,
      _id,
      defined(contactType) => {
        contactType
      },
      ${RICH_TEXT_FRAGMENT(locale, 'content')},
      defined(showLink) => {
        showLink
      }
    },
    ${CUSTOMER_SERVICE_FRAGMENT(locale)}
  }
`)
