import { z } from 'zod'

export const localesSchema = z.enum(['en', 'nl', 'fr', 'de', 'it', 'es', 'dk', 'ch', 'gb'])

export const locales = ['en', 'nl', 'fr', 'de', 'it', 'es', 'dk', 'ch', 'gb'] as const

export type SupportedLocale = (typeof locales)[number]

export const DEFAULT_LOCALE: SupportedLocale = 'en'

export const localesWithFallbackForCoalesceQuery: Record<SupportedLocale, string[]> = {
  en: ['en'],
  gb: ['gb', DEFAULT_LOCALE],
  nl: ['nl', DEFAULT_LOCALE],
  de: ['de', DEFAULT_LOCALE],
  ch: ['ch', 'de', DEFAULT_LOCALE],
  dk: ['dk', DEFAULT_LOCALE],
  it: ['it', DEFAULT_LOCALE],
  fr: ['fr', DEFAULT_LOCALE],
  es: ['es', DEFAULT_LOCALE],
}
export const hreflangMapping: Record<SupportedLocale, string[]> = {
  en: ['en'],
  gb: ['en-gb'],
  nl: ['nl', 'nl-nl'],
  de: ['de', 'de-de'],
  ch: ['de-ch'],
  dk: ['da', 'da-dk'],
  it: ['it', 'it-it'],
  fr: ['fr', 'fr-fr'],
  es: ['es', 'es-es'],
}

export const RETAIL_PROJECT_ID = 'prj_6vqMLlx8Di1TdxcSvVE7ioxpHYu2'

export const STORE_V2_PROJECT_ID = 'prj_NDU78xdJeUMBWO05kyrU9ejHxbmq'
