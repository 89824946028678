import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { CTA_FRAGMENT, CtaSchema } from '../components/cta.fragment'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'

export const SectionTextSchema = z.object({
  _id: z.string(),
  _type: z.literal('sectionText'),
  textAlignementOptions: z.enum(['left', 'right', 'center']),
  title: z.string().optional(),
  text: RichTextSchema.optional(),
  button: CtaSchema.optional(),
  textColor: z.enum(['text-white', 'text-brand-blue']).optional(),
})

export type SectionTextData = z.infer<typeof SectionTextSchema>

export const SECTION_TEXT_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionText' => {
    _id,
    _type,
    textAlignementOptions,
    defined(title) => {
      ${coalesceQuery('title', locale)}
    },
    defined(text) => {
      ${RICH_TEXT_FRAGMENT(locale, 'text')}
    },
    titleFontSize,
    textFontSize,
    defined(titleFontWeight) => {
      titleFontWeight
    },
    defined(button) => {
      button-> {
        ${CTA_FRAGMENT(locale)}
      },
    },
    defined(textColor) => { textColor },
  }
`)
