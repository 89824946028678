import { createContext, FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { usePostHog } from 'posthog-js/react'
import { useCookie, useLocalStorage } from 'react-use'

import { TelemetryUserProperties } from '@/types/telemetry'

import useCart from 'src/hooks/shop/useCart'
import { useCookieConsentContext } from '../hooks/context/useCookieConsentContext'
import useAuthenticatedUserToken from '../hooks/customer/useAuthenticatedUserToken'
import useCustomer from '../hooks/customer/useCustomer'
import { ELEVAR_USER_ID_COOKIE } from '../middleware'
import { algoliaInsightsClient } from '../utils/algolia.util'
import { elevarSessionId, elevarUserId } from '../utils/elevar.util'

interface TelemetryContextProps {
  storeEmailUnverified: (email: string) => void
  userProperties: TelemetryUserProperties | undefined
}

const TelemetryContext = createContext<TelemetryContextProps>({
  storeEmailUnverified: () => {},
  userProperties: undefined,
})

export const useTelemetry = () => {
  const context = useContext(TelemetryContext)

  if (context === undefined) {
    throw new Error('useTelemetry must be used within a TelemetryContextProvider.')
  }

  return context
}

const TelemetryContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const posthog = usePostHog()
  const { cartId } = useCart()
  const { locale } = useRouter()
  const { customer } = useCustomer()
  const authenticatedUserToken = useAuthenticatedUserToken()
  const [customUserId] = useCookie(ELEVAR_USER_ID_COOKIE)
  const { hasConsentedToCookie } = useCookieConsentContext()
  const [isOfficeRequestCookie] = useCookie('isOfficeRequest')
  const consentToMarketing = hasConsentedToCookie('marketing')
  const consentToStatistics = hasConsentedToCookie('statistics')
  const consentToPreferences = hasConsentedToCookie('preferences')
  const [emailUnverified, setEmailUnverified] = useState<string>()
  const [emailUnverifiedStorage, setEmailUnverifiedStorage] = useLocalStorage<string>('emailUnverified')
  const email = customer?.isLoggedIn ? customer?.email || emailUnverifiedStorage : emailUnverifiedStorage

  // Function that can be used by other components when a probable visitor
  // email address is known, e.g. when subscribing to the newsletter
  const storeEmailUnverified = (email: string) => {
    setEmailUnverified(email.trim())
  }

  // Store in local storage if consented
  useEffect(() => {
    if (consentToStatistics) {
      setEmailUnverifiedStorage(emailUnverified)
    }
  }, [consentToStatistics, emailUnverified, setEmailUnverifiedStorage])

  // Posthog auth/consent
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_POSTHOG_KEY) {
      // PostHog: not a 100% guarantee the owner owns this email but good enough
      // @see https://posthog.com/docs/libraries/js#identifying-users
      if (email && consentToStatistics) {
        posthog?.identify(email, { email, websiteLocale: locale })
      } else {
        posthog?.identify(cartId || 'noId', { websiteLocale: locale })
      }
    }
  }, [email, posthog, customer, cartId, locale, consentToStatistics, customUserId])

  // Algolia analytics
  // @see https://www.algolia.com/doc/api-reference/api-methods/init/?client=javascript
  // @see https://www.algolia.com/doc/guides/sending-events/concepts/usertoken/
  useEffect(() => {
    algoliaInsightsClient('init', {
      appId: process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
      apiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
      partial: true,
      useCookie: consentToStatistics,
      region: 'de', // Data region, not locale
      userHasOptedOut: !consentToStatistics,
      ...(customUserId && { userToken: customUserId }),
      ...(authenticatedUserToken && { authenticatedUserToken }),
    })
  }, [authenticatedUserToken, customUserId, consentToStatistics])

  // Upon login, store email in local storage so
  // we'll continue to have that available post-logout
  useEffect(() => {
    if (customer?.isLoggedIn && customer?.email) {
      storeEmailUnverified(customer?.email)
    }
  }, [customer])

  useEffect(() => {
    if (authenticatedUserToken) {
      algoliaInsightsClient('setAuthenticatedUserToken', authenticatedUserToken)
    }
  }, [authenticatedUserToken])

  const handleKlaviyoSubscribe = (e: CustomEvent) => {
    if (e.detail.type == 'submit') {
      const subscribedEmail = e?.detail?.metaData?.$email

      // Some rudimentary email address validation to check event payload
      if (subscribedEmail && subscribedEmail.includes('@')) {
        storeEmailUnverified(subscribedEmail)
      }
    }
  }

  useEffect(() => {
    window?.addEventListener('klaviyoForms', handleKlaviyoSubscribe)

    return () => {
      window?.removeEventListener('klaviyoForms', handleKlaviyoSubscribe)
    }
  }, [handleKlaviyoSubscribe])

  return (
    <TelemetryContext.Provider
      value={{
        storeEmailUnverified,
        userProperties: {
          customer_id: authenticatedUserToken,
          customer_email: email,
          customer_first_name: customer?.isLoggedIn ? customer?.firstName : '',
          customer_last_name: customer?.isLoggedIn ? customer?.lastName : '',
          user_consent: {
            ad_storage: consentToMarketing ? 'granted' : 'denied',
            ad_user_data: consentToMarketing ? 'granted' : 'denied',
            ad_personalization: consentToMarketing ? 'granted' : 'denied',
            analytics_storage: consentToStatistics ? 'granted' : 'denied',
            functionality_storage: consentToPreferences ? 'granted' : 'denied',
            personalization_storage: consentToPreferences ? 'granted' : 'denied',
            security_storage: consentToPreferences ? 'granted' : 'denied',
          },
          visitor_type: customer?.isLoggedIn || false ? 'logged_in' : 'guest',
          // Non-Elevar required but additional info for data layer below here
          email,
          elevar_user_id: elevarUserId(),
          elevar_session_id: elevarSessionId(),
          isOfficeRequest: isOfficeRequestCookie === 'true',
        },
      }}
    >
      {children}
    </TelemetryContext.Provider>
  )
}

export default TelemetryContextProvider
