import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { LINK_FRAGMENT, LinkSchema } from '../components/link.fragment'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'

export const FaqQuestionSchema = z.object({
  _type: z.literal('faqQuestion'),
  _id: z.string(),
  question: z.string(),
  answer: RichTextSchema,
  link: LinkSchema.optional(),
})

export type FaqQuestion = z.infer<typeof FaqQuestionSchema>

const FAQ_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'faqQuestion' => {
    _type,
    _id,
    ${coalesceQuery('question', locale)},
    ${RICH_TEXT_FRAGMENT(locale, 'answer')},
    defined(link) => {
      link-> {
        ${LINK_FRAGMENT(locale)}
      }
    }
  }
`)

export const FaqTopicSchema = z.object({
  _type: z.literal('faqTopic'),
  _id: z.string(),
  title: z.string(),
  faqs: z.array(FaqQuestionSchema),
})

export type FaqTopic = z.infer<typeof FaqTopicSchema>

const FAQ_TOPIC_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'faqTopic' => {
    _type,
    _id,
    ${coalesceQuery('title', locale)},
    'faqs': faqs[@->.showInLocales[$locale] != false]-> {
      ${FAQ_FRAGMENT(locale)}
    }
  }
`)

export const SectionFaqSchema = z.object({
  _type: z.literal('sectionFaq'),
  _id: z.string(),
  hasSearchbar: z.boolean().optional(),
  header: z.string().optional(),
  faqs: z.array(z.union([FaqQuestionSchema, FaqTopicSchema])),
})

export type SectionFaqData = z.infer<typeof SectionFaqSchema>

export const SECTION_FAQ_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionFaq' => {
    _type,
    _id,
    hasSearchbar,
    ${coalesceQuery('header', locale)},
    'faqs': faqs[@->.showInLocales[$locale] != false]-> {
      ${FAQ_FRAGMENT(locale)},
      ${FAQ_TOPIC_FRAGMENT(locale)}
    },
  }
`)
