import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { LINK_FRAGMENT, LinkSchema } from './link.fragment'

export const CtaSchema = z.object({
  variant: z.enum(['primary', 'secondary', 'tertiary', 'transparent', 'secondaryTransparent', 'warning']),
  buttonText: z.string(),
  size: z.enum(['small', 'medium', 'large']),
  icon: z.enum(['none', 'cart']),
  link: LinkSchema.optional(),
})

export type Cta = z.infer<typeof CtaSchema>

export const CTA_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  variant,
  ${coalesceQuery('buttonText', locale)},
  size,
  icon,
  link-> {
    ${LINK_FRAGMENT(locale)}
  }
`)
