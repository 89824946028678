import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../../domain/constants'
import { coalesceLocalizedValue, coalesceQuery } from '../../../utils'
import { LINK_FRAGMENT, LinkSchema } from '../../components/link.fragment'
import { SLIDER_CARD_ITEM_FRAGMENT, SliderCardItemDataSchema } from '../../sections/sectionSlider.fragment'

export const SearchSettingsTermsSchema = z.record(z.string())
export type SearchSettingsTerms = z.infer<typeof SearchSettingsTermsSchema>

export const GLOBAL_SEARCH_SETTINGS_TERMS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
    ${coalesceQuery('recentSearchesTitle', locale)},
    ${coalesceQuery('popularSearchesTitle', locale)},
    ${coalesceQuery('searchProductResultsTitle', locale)},
    ${coalesceQuery('trendingProductsTitle', locale)},
    ${coalesceQuery('searchCollectionResultsTitle', locale)},
    ${coalesceQuery('popularSearchesTitle', locale)},
    ${coalesceQuery('suggestedProductsTitle', locale)},
    ${coalesceQuery('searchArticleResultsTitle', locale)},
    ${coalesceQuery('searchFaqResultsTitle', locale)},
    ${coalesceQuery('searchPageResultsTitle', locale)},
    ${coalesceQuery('searchPlaceholder', locale)},
    ${coalesceQuery('cancel', locale)},
    ${coalesceQuery('clear', locale)},
    ${coalesceQuery('noResultsMessage', locale)},
`)

export const SearchSettingsSchema = z.object({
  discoverCollectionsItems: z.array(SliderCardItemDataSchema),
  searchHelpLinks: z.array(LinkSchema),
  popularSearchTerms: z.array(z.string()),
})

export type SearchSettings = z.infer<typeof SearchSettingsSchema>

// TODO: Remove the check for showInLocales as this is legacy
export const GLOBAL_SEARCH_SETTINGS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
    searchHelpLinks[]->{
      ${LINK_FRAGMENT(locale)}
    },
    ${coalesceLocalizedValue('popularSearchTerms', locale, 'popularSearchTerms', '[]')},
    "discoverCollectionsItems": discoverCollectionsItems->items[@->showInLocales[$locale] != false && (!defined(@->collection) || @->collection->showInLocales[$locale] != false) && @->_type == 'sliderCard'][0...3]-> {
      ${SLIDER_CARD_ITEM_FRAGMENT(locale)}
    }
`)
