import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { MEDIA_FRAGMENT, MediaSchema } from './media.fragment'

export const ImageTextListItemSchema = z.object({
  title: z.string().optional(),
  text: z.string(),
  media: MediaSchema,
})

export type ImageTextListItem = z.infer<typeof ImageTextListItemSchema>

export const IMAGE_TEXT_LIST_ITEM_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  defined(title) => {
    ${coalesceQuery('title', locale)}
  },
  ${coalesceQuery('text', locale)},
  media-> {
    ${MEDIA_FRAGMENT()}
  },
`)
