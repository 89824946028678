import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { ProductTypeSchema } from '../../types'
import { coalesceQuery } from '../../utils'
import { GET_IMAGE_FRAGMENT, ImageSchema } from '../common/getImage.fragment'
import { PRODUCT_CARD_PRODUCT_FRAGMENT, ProductCardProductSchema } from './productCardProduct.fragment'

export const ProductSpotProductSchema = ProductCardProductSchema.extend({
  color: z.object({
    colorName: z.string(),
  }),
  primaryCollectionTitle: z.string(),
  primaryCollectionProductType: ProductTypeSchema,
  showInLocales: z.boolean(),
})

const ProductSpotBaseSchema = z.object({
  x: z.number(),
  y: z.number(),
})

export type ProductSpot = z.infer<typeof ProductSpotBaseSchema> & {
  product: ProductSpotProduct
}

export const ProductSpotSchema: z.ZodType<ProductSpot> = ProductSpotBaseSchema.extend({
  product: z.lazy(() => ProductSpotProductSchema),
})

export const HotspotImageSchema = z.object({
  _type: z.enum(['shopTheLookImage', 'taggedImage']),
  image: ImageSchema,
  productSpots: z.array(ProductSpotSchema),
})

export type ProductSpotProduct = z.infer<typeof ProductSpotProductSchema>
export type HotspotImage = z.infer<typeof HotspotImageSchema>

export const HOTSPOT_IMAGE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type,
  image {
    ${GET_IMAGE_FRAGMENT()}
  },
  productSpots[
    product->.showInLocales[$locale] != false
    && product->.availableInShopify[$locale]
    && (
      *[
        _type == 'collection'
        && product->._id in products[]._ref
        && isPrimary == true
      ][0].showInLocales[$locale] != false
    )
  ] {
    product-> {
      ${PRODUCT_CARD_PRODUCT_FRAGMENT(locale)},
      color-> {
        colorName
      },
      ...*[_type == 'collection' && ^._id in products[]._ref && isPrimary == true][0] {
        ${coalesceQuery('title', locale, 'primaryCollectionTitle')},
        "primaryCollectionProductType": productType,
      },
      'showInLocales': showInLocales[$locale]
    },
    x,
    y,
  }
`)
