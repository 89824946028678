export interface AvailableCountriesDto {
  data: {
    localization: {
      availableCountries: {
        name: string
        isoCode: string
      }[]
      country?: {
        name: string
        isoCode: string
      }
    }
  }
}

export const AVAILABLE_COUNTRIES_QUERY = `
{
  localization {
    availableCountries {
      name
      isoCode
    }
    country {
      name
      isoCode
    }
  }
}`
