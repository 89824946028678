import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SlugSchema } from '../../types'
import { coalesceFilter, coalesceQuery } from '../../utils'

export const BreadcrumbItemSchema = z.object({
  title: z.string(),
  slug: SlugSchema,
  type: z.enum(['current', 'parent', 'grandparent']),
})

export type BreadcrumbItem = z.infer<typeof BreadcrumbItemSchema>

export const CollectionBreadcrumbSchema = z.object({
  items: z.array(BreadcrumbItemSchema),
})

export type CollectionBreadcrumb = z.infer<typeof CollectionBreadcrumbSchema>

/**
 * This fragment is used to get the breadcrumb for a collection.
 */
export const COLLECTION_BREADCRUMB_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
    'items': array::compact([
      {
        'type': 'current',
        ${coalesceQuery('slug', locale)},
        'title': coalesce(
          ${coalesceFilter('shortTitle', locale)},
          ${coalesceFilter('title', locale)}
        )
      },
      ...select(
        defined(parentCollection) => parentCollection[]->{
          'type': 'parent',
          ${coalesceQuery('slug', locale)},
          'title': coalesce(
            ${coalesceFilter('shortTitle', locale)},
            ${coalesceFilter('title', locale)}
          )
        },
        []
      ),
      // Check if the grandparent exists before adding it
      select(
        defined(parentCollection[0]->parentCollection[0]) => parentCollection[0]->parentCollection[0]->{
          'type': 'grandparent',
          ${coalesceQuery('slug', locale)},
          'title': coalesce(
            ${coalesceFilter('shortTitle', locale)},
            ${coalesceFilter('title', locale)}
          )
        },
        null
      )
    ])
`)
