import { CartWarning, ShopifyUserErrorWithoutCode } from '../../types'

export interface UpdateCartAttributesDto {
  data: {
    cartAttributesUpdate: {
      cart: { id: string }
      userErrors: ShopifyUserErrorWithoutCode[]
      warnings: CartWarning[]
    }
  }
}

export const CART_ATTRIBUTES_MUTATION = `mutation cartAttributesUpdate($cartId: ID!, $attributes: [AttributeInput!]!) {
  cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
    cart {
      id
    }
    userErrors {
      field
      message
    }
    warnings {
      code
      target
      message
    }
  }
}`
