import { defineQuery } from 'next-sanity'
import z from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'
import { UPSELL_FRAGMENT, UpsellSchema } from '../components/upsell.fragment'

export const CartSchema = z.object({
  cartTitle: z.string(),
  backToShoppingMessage: z.string(),
  closeButtonMessage: z.string(),
  emptyCartMessage: z.string(),
  cartMention: z
    .object({
      text: z.string().optional(),
      image: MediaSchema.optional(),
    })
    .optional(),
  upsellBanners: z.array(UpsellSchema),
  subtotalText: z.string(),
  checkoutText: z.string(),
  checkoutTextMaintenance: z.string(),
  activateCheckout: z.boolean(),
  recommendedForYou: z.string().optional(),
  frequentlyBoughtTogether: z.string().optional(),
})
export type Cart = z.infer<typeof CartSchema>

export const CART_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
    ${coalesceQuery('cartTitle', locale)},
    ${coalesceQuery('backToShoppingMessage', locale)},
    ${coalesceQuery('closeButtonMessage', locale)},
    ${coalesceQuery('emptyCartMessage', locale)},
    defined(cartMention) => {
      cartMention {
        defined(text) => {
          ${coalesceQuery('text', locale)}
        },
        defined(image) => {
          image-> {
            ${MEDIA_FRAGMENT()}
          }
        }
      }
    },
    upsellBanners[@->showInLocales[$locale] != false]-> {
      ${UPSELL_FRAGMENT(locale)}
    },
    ${coalesceQuery('subtotalText', locale)},
    ${coalesceQuery('checkoutText', locale)},
    ${coalesceQuery('checkoutTextMaintenance', locale)},
    ${coalesceQuery('activateCheckout', locale)},
    defined(recommendedForYou) => {
      ${coalesceQuery('recommendedForYou', locale)}
    },
    defined(frequentlyBoughtTogether) => {
      ${coalesceQuery('frequentlyBoughtTogether', locale)}
    }
  `)
