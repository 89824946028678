import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { GET_IMAGE_FRAGMENT, ImageSchema } from '../common/getImage.fragment'
import { HOTSPOT_IMAGE_FRAGMENT, HotspotImageSchema } from '../components/hotspotImage.fragment'

export const SectionTaggedImageSchema = z.object({
  _id: z.string(),
  ...HotspotImageSchema.shape,
  _type: z.enum(['shopTheLookImage', 'taggedImage', 'sectionTaggedImage']),
  mobileImage: ImageSchema.optional(),
})

export type SectionTaggedImageData = z.infer<typeof SectionTaggedImageSchema>

export const SECTION_TAGGED_IMAGE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == "shopTheLookImage" || _type == "taggedImage" || _type == "sectionTaggedImage" => {
    _id,
    _type,
    defined(productSpots) => {
      ${HOTSPOT_IMAGE_FRAGMENT(locale)}
    },
    defined(taggedImage) => {
      ...taggedImage-> {
        ${HOTSPOT_IMAGE_FRAGMENT(locale)}
      }
    },
    defined(mobileImage) => {
      mobileImage {
        ${GET_IMAGE_FRAGMENT()}
      }
    }
  }
`)
