import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { LINK_FRAGMENT, LinkSchema } from '../components/link.fragment'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'

export const SectionResetPasswordSchema = z.object({
  _id: z.string(),
  _type: z.literal('sectionResetPassword'),
  title: z.string(),
  subtitle: z.string().optional(),
  backgroundImage: MediaSchema,
  passwordLabel: z.string(),
  passwordErrorMessage: z.string(),
  confirmPasswordLabel: z.string(),
  passwordMatchErrorMessage: z.string(),
  submitButtonText: z.string(),
  submitSuccessTitle: z.string(),
  submitSuccessSubtitle: z.string().optional(),
  returnToLoginLink: LinkSchema,
})

export type SectionResetPasswordData = z.infer<typeof SectionResetPasswordSchema>

export const SECTION_RESET_PASSWORD_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionResetPassword' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    defined(subtitle) => {
      ${coalesceQuery('subtitle', locale)},
    },
    backgroundImage -> {
      ${MEDIA_FRAGMENT()}
    },
    ${coalesceQuery('passwordLabel', locale)},
    ${coalesceQuery('passwordErrorMessage', locale)},
    ${coalesceQuery('confirmPasswordLabel', locale)},
    ${coalesceQuery('passwordMatchErrorMessage', locale)},
    ${coalesceQuery('submitButtonText', locale)},
    ${coalesceQuery('submitSuccessTitle', locale)},
    defined(submitSuccessSubtitle) => {
      ${coalesceQuery('submitSuccessSubtitle', locale)}
    },
    returnToLoginLink-> {
      ${LINK_FRAGMENT(locale)}
    }
  }
`)
