import { useEffect } from 'react'
import { useRouter } from 'next/router'
import useSWR from 'swr'

import { IronSessionCustomer } from '../../utils/auth.util'

export const useCustomer = (redirectTo: boolean | string = false, redirectIfFound = false) => {
  const { push, locale } = useRouter()

  const {
    data: customer,
    mutate: mutateCustomer,
    isLoading,
  }: { data: IronSessionCustomer; mutate: any; isLoading: boolean } = useSWR('/api/shopify/account/customer', (url) => fetch(url).then((res) => res.json()), {
    keepPreviousData: true,
    fallbackData: { isLoggedIn: false },
  })

  useEffect(() => {
    if (isLoading || !redirectTo || !customer) {
      return
    }

    if (
      // If redirectTo is set, redirect if the customer was not found.
      (redirectTo && !redirectIfFound && !customer?.isLoggedIn) ||
      // If redirectIfFound is also set, redirect if the customer was found
      (redirectIfFound && customer?.isLoggedIn)
    ) {
      push(String(redirectTo), undefined, { locale })
    }
  }, [customer, redirectIfFound, redirectTo, isLoading, push, locale])

  return { customer, mutateCustomer, isLoading }
}

export default useCustomer
