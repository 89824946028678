import { defineQuery } from 'next-sanity'
import { z } from 'zod'

export const SectionAftershipSchema = z.object({
  _type: z.literal('sectionAftership'),
  _id: z.string(),
})

export type SectionAftershipData = z.infer<typeof SectionAftershipSchema>

export const SECTION_AFTERSHIP_FRAGMENT = () =>
  defineQuery(`
  _type == 'sectionAftership' => {
    _id,
    _type,
  }
`)
