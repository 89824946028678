import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { GET_IMAGE_FRAGMENT, ImageSchema } from '../common/getImage.fragment'
import { LINK_FRAGMENT, LinkSchema } from '../components/link.fragment'

export const SectionImageLinkSchema = z.object({
  _id: z.string(),
  _type: z.literal('sectionImageLink'),
  title: z.string(),
  image: ImageSchema,
  link: LinkSchema,
  asButton: z.boolean(),
})

export type SectionImageLinkData = z.infer<typeof SectionImageLinkSchema>

export const SECTION_IMAGE_LINK_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionImageLink' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    image {
      ${GET_IMAGE_FRAGMENT()}
    },
    link-> {
      ${LINK_FRAGMENT(locale)}
    },
    asButton,
  }
`)
