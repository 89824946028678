import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SlugSchema } from '../../types'
import { coalesceQuery } from '../../utils'
import { LINK_FRAGMENT, LinkSchema } from '../components/link.fragment'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'

export const PageStorySchema = z.object({
  _type: z.literal('pageStory'),
  _id: z.string(),
  slug: SlugSchema,
  preTitle: z.string(),
  title: z.string(),
  excerpt: RichTextSchema.optional(),
  thumbnail: MediaSchema,
  link: LinkSchema.optional(),
})

export type PageStory = z.infer<typeof PageStorySchema>

export const SectionStoriesSchema = z.object({
  _type: z.literal('sectionStories'),
  _id: z.string(),
  title: z.string(),
  items: z.array(PageStorySchema),
})

export type SectionStoriesData = z.infer<typeof SectionStoriesSchema>

export const SECTION_STORIES_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
_type == 'sectionStories' => {
  _type,
  _id,
  ${coalesceQuery('title', locale)},
  items[]-> {
    _type,
    _id,
    ${coalesceQuery('slug', locale)},
    ${coalesceQuery('preTitle', locale)},
    ${coalesceQuery('title', locale)},
    defined(excerpt) => {
      ${RICH_TEXT_FRAGMENT(locale, 'excerpt')}
    },
    thumbnail-> {
      ${MEDIA_FRAGMENT()}
    },
    link-> {
      ${LINK_FRAGMENT(locale)}
    },
   } | order(dateTime(dateCreated) desc)
 }
`)
