import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { SECTION_TEXT_FRAGMENT, SectionTextSchema } from './sectionText.fragment'

export const SectionWishlistSchema = z.object({
  _type: z.literal('sectionWishlist'),
  _id: z.string(),
  wishlistTitle: SectionTextSchema,
  wishlistEmptyStatement: z.string(),
  saveWishlistButtonText: z.string(),
  loggedOutWishListWithItemsStatement: z.string().optional(),
  loggedInWishListWithItemsStatement: z.string().optional(),
})

export type SectionWishlistData = z.infer<typeof SectionWishlistSchema>

export const SECTION_WISHLIST_FRAGMENT = (locale = DEFAULT_LOCALE) => `
_type == 'sectionWishlist' => {
  _id,
  _type,
  wishlistTitle -> {
    ${SECTION_TEXT_FRAGMENT(locale)},
  },
  defined(loggedOutWishListWithItemsStatement) => {
    ${coalesceQuery('loggedOutWishListWithItemsStatement', locale)}
  },
  defined(loggedInWishListWithItemsStatement) => {
    ${coalesceQuery('loggedInWishListWithItemsStatement', locale)}
  },
  ${coalesceQuery('wishlistEmptyStatement', locale)},
  ${coalesceQuery('saveWishlistButtonText', locale)},
 }
`
