import { defineQuery } from 'next-sanity'

import { DEFAULT_LOCALE } from '../../../../domain/constants'
import { coalesceQuery } from '../../../utils'

// Global products; should also be in Sanity schema
// @see apps/studio/schemas/dictionary/global-products.js
export const globalProductsText: string[] = [
  'addToCartButtonText',
  'addToCartButtonSoldOutText',
  'addToCartButtonComingSoonText',
  'chooseSize',
  'noVariantError',
  'noLengthError',
  'noWaistError',
  'noAmountError',
  'serverError',
  'onlyQuantityLeftText',
  'notifyMeText',
  'notifyMeTextQuickATC',
  'notifyMeSubmittedText',
  'notifyMeEmailPlaceholder',
  'pleaseSelectSizeForNotify',
  'availableColors',
  'reviews',
  'sizeCharts',
  'sizeGuide',
  'descriptionTitle',
  'sizingTitle',
  'sustainability',
  'materialTitle',
  'detailsTitle',
  'policiesTitle',
  'materials',
  'policies',
  'optionWaist',
  'optionLength',
  'optionSize',
  'optionAll',
  'youMayAlsoLike',
  'modelSentence',
]

export const GLOBAL_PRODUCTS_FRAGMENT = (locale = DEFAULT_LOCALE) => defineQuery(globalProductsText.map((term) => `${coalesceQuery(term, locale)}`).join(','))
