import { createContext, FunctionComponent, PropsWithChildren, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useToggle } from 'react-use'

import { at } from 'utilities/array/arrayAt'
import { getErrorMessage } from 'utilities/errors/errorMessage'

import { ShopifyProductInSanity } from '@/types/product'
import { WishlistObject } from '@/types/wishlist'

import useCustomer from '../hooks/customer/useCustomer'
import { getLocalWishlist, synchronizeWishlist, updateWishlist } from '../utils/wishlist.util'
import { useUI } from './UI.context'

export interface WishlistContextType {
  updateItems: (product: ShopifyProductInSanity, action: 'add' | 'remove') => Promise<void>
  itemInWishlistCheck: (product: ShopifyProductInSanity, locale: string | undefined) => any
  wishlist: WishlistObject | undefined
  productIds: string[]
  loading: boolean
}

export const WishlistContext = createContext<undefined | WishlistContextType>(undefined)

export const WishlistContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { customer } = useCustomer()
  const { locale } = useRouter()
  const { updateWishlistCount } = useUI()
  const [loading, setLoading] = useToggle(true)
  const [wishlist, setWishlist] = useState<WishlistObject | undefined>(undefined)
  const [productIds, setProductIds] = useState<string[]>([])

  const getWishlist = async () => {
    setLoading(true)

    // If there is a customer, then we get the wishlist from the Shopify customer metafield
    try {
      const loggedInUserWishlist = await synchronizeWishlist({ customer, locale })
      setWishlist(loggedInUserWishlist)
      setLoading(false)
    } catch (error) {
      console.error(getErrorMessage(error))
    }
  }

  useEffect(() => {
    if (!customer || !locale) {
      return
    }

    getWishlist()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, locale])

  useEffect(() => {
    const productIds: string[] = Object.entries(wishlist?.items || []).flatMap(([id, item]) => (item?.inWishlist ? id : []))

    setProductIds(productIds)
    updateWishlistCount(productIds.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishlist])

  const updateItems = async (product: ShopifyProductInSanity, action: 'add' | 'remove') => {
    updateWishlist({ customer, locale, product, action })
    getWishlist()
  }

  const itemInWishlistCheck = (product: ShopifyProductInSanity, locale: string | undefined) => {
    const { items, lastUpdated } = getLocalWishlist(locale)
    const id = parseInt(String(at((product.shopifyProductId || '').split('/'), -1) || ''))

    if (!lastUpdated || !items[id]) {
      return false
    }

    return items[id]?.inWishlist || false
  }

  const value = {
    updateItems,
    itemInWishlistCheck,
    wishlist,
    productIds,
    loading,
  }

  return <WishlistContext.Provider value={value}>{children}</WishlistContext.Provider>
}
