import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SECTION_TEXT_FRAGMENT, SectionTextSchema } from './sectionText.fragment'

export const SectionTwoColumnsSchema = z.object({
  _type: z.literal('sectionTwoColumns'),
  _id: z.string(),
  sections: z.array(SectionTextSchema),
  width: z.enum(['default', 'fluid', 'small']),
})

export type SectionTwoColumnsData = z.infer<typeof SectionTwoColumnsSchema>

export const SECTION_TWO_COLUMNS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionTwoColumns' => {
    _id,
    _type,
    sections[]-> {
      ${SECTION_TEXT_FRAGMENT(locale)}
    },
    width,
  }
`)
