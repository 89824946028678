import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { CTA_FRAGMENT, CtaSchema } from '../components/cta.fragment'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'

export const SectionGridCalloutsSchema = z.object({
  _id: z.string(),
  _type: z.literal('gridCallout'),
  /**
   * Is true when:
   * - hideDynamically is set to false (this indicates that the grid callout should always be shown)
   * - the linked product is in good stock (configured in Sanity), available in Shopify and visible in the current locale
   * - the linked collection should be visible in the current locale and has products which should show in the current locale
   * - the linked page is visible in the current locale
   * - the linked page is not a collection, product or page
   */
  showGridCallout: z.boolean(),
  image: MediaSchema,
  text: z.string(),
  button: CtaSchema.optional(),
  columnSpan: z.number(),
})

export type SectionGridCalloutsData = z.infer<typeof SectionGridCalloutsSchema>

export const SECTION_GRID_CALLOUT_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`{
    _id,
    _type,
    'page': button->link->page->,
    button-> {
      ${CTA_FRAGMENT(locale)}
    },
    ${coalesceQuery('text', locale)},
    "image": image-> {
      ${MEDIA_FRAGMENT()}
    },
    columnSpan,
  } {
    _id,
    _type,
    button,
    text,
    image,
    columnSpan,
    'showGridCallout': select(
      advancedSettings.hideDynamically == false => true,
      page._type == 'collection' => page.showInLocales[$locale] != false && count(page.products[@->showInLocales.[$locale] != false]) > 0,
      page._type == 'product' => count(page.shopifyVariants[@->inventory[locationId == $inventoryLocationId][0].quantity > coalesce(advancedSettings.showAtStockNumber, 20)]) > 0 && page.availableInShopify[$locale] && page.showInLocales[$locale] != false,
      page._type == 'page' => page.showInLocales[$locale] != false,
      page._type != 'collection' && page._type != 'product' && page._type != 'page' => true
    ),
  }`)
